.dxbl-btn-tool.dxbl-btn-secondary:not(.dxbl-btn-standalone),
.dxbl-btn-split > .dxbl-btn-tool.dxbl-btn-secondary:not(.dxbl-btn-standalone) {
    @include button-secondary-tool-style();
    @include button-focus-style($dx-primary, "primary");
}

.dxbl-btn-tool.dxbl-btn-outline-secondary:not(.dxbl-btn-standalone),
.dxbl-btn-split > .dxbl-btn-tool.dxbl-btn-outline-secondary:not(.dxbl-btn-standalone) {
    @include button-secondary-outline-tool-style();
    @include button-focus-style($dx-primary, "primary");
}

.dxbl-btn-tool.dxbl-btn-text-secondary:not(.dxbl-btn-standalone),
.dxbl-btn-split > .dxbl-btn-tool.dxbl-btn-text-secondary:not(.dxbl-btn-standalone) {
    @include button-secondary-text-tool-style();
    @include button-focus-style($dx-primary, "primary");
}

.dxbl-btn-tool,
.dxbl-btn-split > .dxbl-btn-tool:not(.dxbl-btn-standalone) {
    @include button-tool-paddings();
}

.dxbl-btn-tool.dxbl-sm:not(.dxbl-btn-standalone),
.dxbl-btn-split > .dxbl-btn-tool.dxbl-sm:not(.dxbl-btn-standalone) {
    @include button-tool-paddings-sm();
}

.dxbl-btn-tool.dxbl-lg:not(.dxbl-btn-standalone),
.dxbl-btn-split > .dxbl-btn-tool.dxbl-lg:not(.dxbl-btn-standalone) {
    @include button-tool-paddings-lg();
}
