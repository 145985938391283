.dxbl-grid[data-dx-focus-hidden] *:focus,
.dxbl-grid tr[data-dx-focus-hidden] > td:focus,
.dxbl-calendar[data-dx-focus-hidden] *:focus-within,
[data-dx-focus-hidden] .dxbs-clear-btn:focus, [data-toggle="gridview-column-sort"]:focus {
    outline: 0 !important;
}

[data-dx-focus-hidden] .dxbs-clear-btn {
    background: none;
}
