@import "mixins/common";
@import "mixins/navigation";
@import "mixins/menu";

.dxbl-menu {
    --dxbl-menu-item-hover-color: #{$dx-menu-item-hover-color};
    --dxbl-menu-item-hover-bg: #{$dx-menu-item-hover-bg};
    --dxbl-menu-item-selected-bg: #{$dx-menu-item-selected-bg};
    --dxbl-menu-item-selected-color: #{$dx-menu-item-selected-color};
    --dxbl-menu-item-selected-hover-bg: #{$dx-menu-item-selected-hover-bg};
    --dxbl-menu-item-selected-hover-color: #{$dx-menu-item-selected-hover-color};
    --dxbl-menu-item-disabled-bg: #{$dx-menu-item-disabled-bg};
    --dxbl-menu-item-disabled-color: #{$dx-menu-item-disabled-color};
    --dxbl-menu-item-disabled-opacity: #{$dx-menu-item-disabled-opacity};
    --dxbl-menu-font-family: #{$dx-menu-font-family};
    --dxbl-menu-color: #{$dx-menu-color};
    --dxbl-menu-separator-width: #{$dx-menu-separator-width};
    --dxbl-menu-separator-color: #{$dx-menu-separator-color};
    --dxbl-menu-separator-style: #{$dx-menu-separator-style};
    --dxbl-menu-item-image-size: #{$dx-menu-item-image-size};
    --dxbl-menu-bg: #{$dx-menu-bg};
    --dxbl-menu-top-left-border-radius: #{$dx-menu-top-left-border-radius};
    --dxbl-menu-top-right-border-radius: #{$dx-menu-top-right-border-radius};
    --dxbl-menu-bottom-left-border-radius: #{$dx-menu-bottom-left-border-radius};
    --dxbl-menu-bottom-right-border-radius: #{$dx-menu-bottom-right-border-radius};
    --dxbl-menu-title-font-weight: #{$dx-menu-title-font-weight};
    --dxbl-menu-title-font-size: #{$dx-menu-title-font-size};
    --dxbl-menu-title-line-height: #{$dx-menu-title-line-height};
    --dxbl-menu-border-width: #{$dx-menu-border-width};
    --dxbl-menu-border-color: #{$dx-menu-border-color};
    --dxbl-menu-border-style: #{$dx-menu-border-style};
    --dxbl-menu-item-focus-outline-color: #{$dx-menu-item-focus-outline-color};
    --dxbl-menu-item-focus-outline-width: #{$dx-menu-item-focus-outline-width};
    --dxbl-menu-item-focus-outline-offset: #{$dx-menu-item-focus-outline-offset};

    display: flex;
    flex-direction: column;
    background-color: var(--dxbl-menu-bg);
    border-radius: var(--dxbl-menu-top-left-border-radius) var(--dxbl-menu-top-right-border-radius) var(--dxbl-menu-bottom-right-border-radius) var(--dxbl-menu-bottom-left-border-radius);
    border: var(--dxbl-menu-border-width) var(--dxbl-menu-border-style) var(--dxbl-menu-border-color);

    &.dxbl-sm {
        --dxbl-menu-title-font-size: #{$dx-menu-title-font-size-sm};
        --dxbl-menu-item-font-size: #{$dx-menu-item-font-size-sm};
        --dxbl-menu-item-image-size: #{$dx-menu-item-image-size-sm};
    }

    &.dxbl-lg {
        --dxbl-menu-title-font-size: #{$dx-menu-title-font-size-lg};
        --dxbl-menu-item-font-size: #{$dx-menu-item-font-size-lg};
        --dxbl-menu-item-image-size: #{$dx-menu-item-image-size-lg};
    }

    &:not([data-dx-menu-loaded]) {
        & > .dxbl-menu-nav,
        & > .dxbl-menu-item-list,
        & > .dxbl-menu-title {
            opacity: 0;
        }

        & > .dxbl-menu-bar {
            opacity: 0;

            & > .dxbl-menu-item-list {
                flex-wrap: nowrap;
            }
        }
    }

    &:not([data-dx-menu-mobile]) {
        & > .dxbl-menu-nav,
        & > .dxbl-menu-item-list,
        & > .dxbl-menu-title {
            display: none;
        }
    }

    &[data-dx-menu-mobile] > .dxbl-menu-bar {
        display: none;
    }

    &:not([data-dx-focus-hidden]) {
        .dxbl-menu-item-list > .dxbl-menu-list-item > .dxbl-menu-item {
            & > .dxbl-btn,
            & > .dxbl-menu-item-tmpl {
                &:focus-visible {
                    outline: var(--dxbl-menu-item-focus-outline-width) solid var(--dxbl-menu-item-focus-outline-color);
                    outline-offset: calc(var(--dxbl-menu-item-focus-outline-width) * -1);
                }
            }
        }
    }

    .dxbl-menu-item-list {
        @extend %menu-item-list;

        & > .dxbl-menu-list-item {
            &.dxbl-menu-list-item-stretch {
                flex: 1 1 auto;
            }

            &.dxbl-menu-item-pos-start .dxbl-btn {
                justify-content: flex-start;
            }

            &.dxbl-menu-item-pos-center .dxbl-btn {
                justify-content: center;
            }

            &.dxbl-menu-item-pos-end .dxbl-btn {
                justify-content: flex-end;
            }

            & > .dxbl-menu-item {
                @extend %menu-item;

                & > .dxbl-btn {
                    @include menu-item-btn(
                        "--dxbl-menu-font-family",
                        "--dxbl-menu-item-font-size",
                        "--dxbl-menu-item-line-height",
                        "--dxbl-menu-color",
                        "--dxbl-menu-item-padding-x",
                        "--dxbl-menu-item-padding-y",
                        "--dxbl-menu-item-image-spacing",
                        "--dxbl-menu-item-disabled-bg",
                        "--dxbl-menu-item-disabled-color",
                        "--dxbl-menu-item-disabled-opacity",
                        "--dxbl-menu-item-hover-bg",
                        "--dxbl-menu-item-hover-color",
                        "--dxbl-menu-item-selected-bg",
                        "--dxbl-menu-item-selected-color",
                        "--dxbl-menu-item-selected-hover-bg",
                        "--dxbl-menu-item-selected-hover-color"
                    );

                    & > .dxbl-menu-item-text-container {
                        &.dxbl-menu-text-hidden {
                            display: none;
                            overflow: hidden;
                            width: 0;
                            padding: 0;
                        }

                        .dxbl-menu-item-text {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        & > .dxbl-menu-spacing {
            flex: 1;
        }
    }

    .dxbl-menu-title {
        font-size: var(--dxbl-menu-title-font-size);
        font-weight: var(--dxbl-menu-title-font-weight);
        line-height: var(--dxbl-menu-title-line-height);
        align-self: stretch;
        white-space: nowrap;
    }

    & > .dxbl-menu-bar {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 100%;

        &.dxbl-menu-loading {
            visibility: hidden;
            width: 1px;
        }

        & > .dxbl-menu-title {
            align-self: center;
        }

        & > .dxbl-menu-item-list {
            display: flex;
            flex: 1 1 auto;

            &.dxbl-menu-loading {
                visibility: hidden;
                width: 1px;
                flex: 0 1 auto;
            }

            & > .dxbl-menu-list-item {
                position: static;
            }
        }
    }
}

@import "menu.dropdown";
@import "menu.horizontal";
@import "menu.vertical";
