@import "mixins/button";

.dxbl-btn {
    --dxbl-btn-font-family: #{$dx-btn-font-family};
    --dxbl-btn-font-size: #{$dx-btn-font-size};
    --dxbl-btn-line-height: #{$dx-btn-line-height};
    --dxbl-btn-bg: #{$dx-btn-bg};
    --dxbl-btn-color: #{$dx-btn-color};
    --dxbl-btn-padding-x: #{$dx-btn-padding-x-ratio * $dx-btn-padding-x};
    --dxbl-btn-padding-y: #{$dx-btn-padding-y};
    --dxbl-btn-image-spacing: #{$dx-btn-image-spacing};
    --dxbl-btn-border-color: #{$dx-btn-border-color};
    --dxbl-btn-border-style: #{$dx-btn-border-style};
    --dxbl-btn-border-width: #{$dx-btn-border-width};
    --dxbl-btn-border-radius: #{$dx-btn-border-radius};
    --dxbl-btn-box-shadow: #{$dx-btn-box-shadow};
    --dxbl-btn-active-bg: var(--dxbl-btn-bg);
    --dxbl-btn-active-color: var(--dxbl-btn-color);
    --dxbl-btn-active-border-color: var(--dxbl-btn-border-color);
    --dxbl-btn-active-box-shadow: var(--dxbl-btn-box-shadow);
    --dxbl-btn-active-padding-x: var(--dxbl-btn-padding-x);
    --dxbl-btn-active-padding-y: var(--dxbl-btn-padding-y);
    --dxbl-btn-active-border-width: var(--dxbl-btn-border-width);
    --dxbl-btn-active-border-radius: var(--dxbl-btn-border-radius);
    --dxbl-btn-hover-bg: var(--dxbl-btn-bg);
    --dxbl-btn-hover-color: var(--dxbl-btn-color);
    --dxbl-btn-hover-border-color: var(--dxbl-btn-border-color);
    --dxbl-btn-hover-box-shadow: var(--dxbl-btn-box-shadow);
    --dxbl-btn-hover-padding-x: var(--dxbl-btn-padding-x);
    --dxbl-btn-hover-padding-y: var(--dxbl-btn-padding-y);
    --dxbl-btn-hover-border-width: var(--dxbl-btn-border-width);
    --dxbl-btn-hover-border-radius: var(--dxbl-btn-border-radius);
    --dxbl-btn-disabled-bg: var(--dxbl-btn-bg);
    --dxbl-btn-disabled-color: var(--dxbl-btn-color);
    --dxbl-btn-disabled-border-color: var(--dxbl-btn-border-color);
    --dxbl-btn-disabled-box-shadow: var(--dxbl-btn-box-shadow);
    --dxbl-btn-disabled-padding-x: var(--dxbl-btn-padding-x);
    --dxbl-btn-disabled-padding-y: var(--dxbl-btn-padding-y);
    --dxbl-btn-disabled-border-width: var(--dxbl-btn-border-width);
    --dxbl-btn-disabled-border-radius: var(--dxbl-btn-border-radius);
    --dxbl-btn-focus-bg: var(--dxbl-btn-bg);
    --dxbl-btn-focus-color: var(--dxbl-btn-color);
    --dxbl-btn-focus-border-bg: var(--dxbl-btn-border-color);
    --dxbl-btn-focus-border-color: var(--dxbl-btn-border-color);
    --dxbl-btn-focus-box-shadow: 0 0 var(--dxbl-btn-focus-shadow-blur) var(--dxbl-btn-focus-shadow-spread) var(--dxbl-btn-focus-shadow-color);
    --dxbl-btn-focus-padding-x: var(--dxbl-btn-padding-x);
    --dxbl-btn-focus-padding-y: var(--dxbl-btn-padding-y);
    --dxbl-btn-focus-border-width: var(--dxbl-btn-border-width);
    --dxbl-btn-focus-border-radius: var(--dxbl-btn-border-radius);
    --dxbl-btn-focus-outline-color: trasparent;
    --dxbl-btn-focus-outline-size: 0;
    --dxbl-btn-focus-outline-offset: 0;
    --dxbl-btn-link-hover-color: #{$dx-primary};
    --dxbl-btn-link-text-decoration: underline;
    --dxbl-btn-disabled-opacity: #{$dx-btn-disabled-bg-tint-amount};

    @include button-focus-style($dx-primary, "primary");

    background-color: var(--dxbl-btn-bg);
    border-radius: var(--dxbl-btn-border-radius);
    border: var(--dxbl-btn-border-width) var(--dxbl-btn-border-style) var(--dxbl-btn-border-color);
    box-shadow: var(--dxbl-btn-box-shadow);
    color: var(--dxbl-btn-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--dxbl-btn-font-family);
    font-size: var(--dxbl-btn-font-size);
    line-height: var(--dxbl-btn-line-height);
    padding: var(--dxbl-btn-padding-y) var(--dxbl-btn-padding-x);
    outline: none;

    & > .dxbl-image + *,
    & > :not(.dxbl-image) + .dxbl-image:not(:first-child) {
        margin-left: var(--dxbl-btn-image-spacing);
    }

    @include link-buttons-style() {
        --dxbl-btn-hover-color: var(--dxbl-btn-link-hover-color);
        --dxbl-btn-box-shadow: none; // No token

        span:not(.dxbl-image) {
            text-decoration: var(--dxbl-btn-link-text-decoration);
        }
    }

    @include active-state() {
        border-color: var(--dxbl-btn-active-border-color);
        background: var(--dxbl-btn-active-background);
        background-color: var(--dxbl-btn-active-bg);
        color: var(--dxbl-btn-active-color);

        @include button-state-conditional-properties($dx-state-active);
    }

    @include hover-state() {
        border-color: var(--dxbl-btn-hover-border-color);
        background: var(--dxbl-btn-hover-background);
        background-color: var(--dxbl-btn-hover-bg);
        color: var(--dxbl-btn-hover-color);

        @include button-state-conditional-properties($dx-state-hover);
    }

    @include disabled-state() {
        border-color: var(--dxbl-btn-disabled-border-color);
        background: var(--dxbl-btn-disabled-background);
        background-color: var(--dxbl-btn-disabled-bg);
        color: var(--dxbl-btn-disabled-color);
        opacity: var(--dxbl-btn-disabled-opacity);

        @include button-state-conditional-properties($dx-state-disabled);
    }

    @include button-focus-state() {
        border-color: var(--dxbl-btn-focus-border-color);
        background: var(--dxbl-btn-focus-background);
        background-color: var(--dxbl-btn-focus-bg);
        outline: var(--dxbl-btn-focus-outline-color) solid var(--dxbl-btn-focus-outline-size);
        outline-offset: var(--dxbl-btn-focus-outline-offset);

        @include button-state-conditional-properties($dx-state-focused, true);
    }

    &.dxbl-sm:not(.dxbl-btn-standalone) {
        --dxbl-btn-font-size: #{$dx-btn-font-size-sm};
        --dxbl-btn-line-height: #{$dx-btn-line-height-sm};
        --dxbl-btn-padding-x: #{$dx-btn-padding-x-ratio * $dx-btn-padding-x-sm};
        --dxbl-btn-padding-y: #{$dx-btn-padding-y-sm};
        --dxbl-btn-image-spacing: #{$dx-btn-image-spacing-sm};
        --dxbl-btn-border-radius: #{$dx-btn-border-radius-sm};
    }

    &.dxbl-lg:not(.dxbl-btn-standalone) {
        --dxbl-btn-font-size: #{$dx-btn-font-size-lg};
        --dxbl-btn-line-height: #{$dx-btn-line-height-lg};
        --dxbl-btn-padding-x: #{$dx-btn-padding-x-ratio * $dx-btn-padding-x-lg};
        --dxbl-btn-padding-y: #{$dx-btn-padding-y-lg};
        --dxbl-btn-image-spacing: #{$dx-btn-image-spacing-lg};
        --dxbl-btn-border-radius: #{$dx-btn-border-radius-lg};
    }
}

@each $render-style, $value in $dx-theme-colors {
    .dxbl-btn-#{$render-style}:not(.dxbl-btn-standalone) {
        @include button-style($value, $render-style);
    }

    .dxbl-btn-outline-#{$render-style}:not(.dxbl-btn-standalone) {
        @include button-outline-style($value, $render-style);
    }

    .dxbl-btn-text-#{$render-style}:not(.dxbl-btn-standalone) {
        @include button-text-style($value, $render-style);
    }
}

@import "button.container";
@import "button.group";
@import "button.tool";
@import "button.split";
@import "button.standalone";
