@import "common";

@mixin state-selected-range-style($bg-color-var, $color-var, $border-width-var: null, $root-bg-color-var: null) {
    @include element-calculated-style($bg-color-var, $color-var, $dx-calendar-item-selected-range-bg,
        $dx-state-base-bg, $dx-calendar-item-selected-range-bg-alpha, $border-width-var, $root-bg-color-var);
}

@mixin calendar-item-focus-state() {
    &:focus-within {
        outline: var(--dxbl-calendar-item-focus-outline-width) solid var(--dxbl-calendar-item-focus-outline-color);
        outline-offset: calc(var(--dxbl-calendar-item-focus-outline-width) * -1);
    }
}

@mixin calendar-button-focus-state() {
    --dxbl-btn-focus-box-shadow: none;
    --dxbl-btn-focus-outline-color: var(--dxbl-calendar-item-focus-outline-color);
    --dxbl-btn-focus-outline-size: var(--dxbl-calendar-item-focus-outline-width);
    --dxbl-btn-focus-outline-offset: var(--dxbl-calendar-footer-btn-outline-offset);
}
