@import "mixins/menu";

.dxbl-menu-dropdown {
    --dxbl-menu-item-hover-color: #{$dx-menu-item-hover-color};
    --dxbl-menu-item-hover-bg: #{$dx-menu-item-hover-bg};
    --dxbl-menu-separator-width: #{$dx-menu-separator-width};
    --dxbl-menu-separator-color: #{$dx-menu-separator-color};
    --dxbl-menu-separator-style: #{$dx-menu-separator-style};
    --dxbl-menu-item-selected-bg: #{$dx-menu-item-selected-bg};
    --dxbl-menu-item-selected-color: #{$dx-menu-item-selected-color};
    --dxbl-menu-item-selected-hover-bg: #{$dx-menu-item-selected-hover-bg};
    --dxbl-menu-item-selected-hover-color: #{$dx-menu-item-selected-hover-color};
    --dxbl-menu-item-disabled-bg: #{$dx-menu-item-disabled-bg};
    --dxbl-menu-item-disabled-color: #{$dx-menu-item-disabled-color};
    --dxbl-menu-item-disabled-opacity: #{$dx-menu-item-disabled-opacity};
    --dxbl-menu-font-family: #{$dx-menu-font-family};
    --dxbl-menu-color: #{$dx-menu-color};
    --dxbl-menu-bg: #{$dx-menu-bg};
    --dxbl-menu-item-font-size: #{$dx-menu-dropdown-item-font-size};
    --dxbl-menu-item-line-height: #{$dx-menu-dropdown-item-line-height};
    --dxbl-menu-item-image-spacing: #{$dx-menu-dropdown-item-image-spacing};
    --dxbl-menu-item-padding-x: #{$dx-menu-dropdown-item-padding-x};
    --dxbl-menu-item-padding-y: #{$dx-menu-dropdown-item-padding-y};
    --dxbl-menu-dropdown-min-width: #{$dx-menu-dropdown-min-width};
    --dxbl-menu-dropdown-padding-x: #{$dx-menu-dropdown-padding-x};
    --dxbl-menu-dropdown-padding-y: #{$dx-menu-dropdown-padding-y};
    --dxbl-menu-dropdown-border-radius: #{$dx-menu-dropdown-border-radius};
    --dxbl-menu-dropdown-border-width: #{$dx-menu-dropdown-border-width};
    --dxbl-menu-dropdown-border-color: #{$dx-menu-dropdown-border-color};
    --dxbl-menu-dropdown-border-style: #{$dx-menu-dropdown-border-style};
    --dxbl-menu-item-image-size: #{$dx-menu-item-image-size};
    --dxbl-dropdown-bg: var(--dxbl-menu-bg);
    --dxbl-menu-dropdown-item-focus-outline-color: #{$dx-menu-dropdown-item-focus-outline-color};
    --dxbl-menu-dropdown-item-focus-outline-width: #{$dx-menu-dropdown-item-focus-outline-width};

    &.dxbl-sm {
        --dxbl-menu-item-line-height: #{$dx-menu-dropdown-item-line-height-sm};
        --dxbl-menu-item-font-size: #{$dx-menu-dropdown-item-font-size-sm};
        --dxbl-menu-item-image-spacing: #{$dx-menu-dropdown-item-image-spacing-sm};
        --dxbl-menu-item-padding-x: #{$dx-menu-dropdown-item-padding-x-sm};
        --dxbl-menu-item-padding-y: #{$dx-menu-dropdown-item-padding-y-sm};
        --dxbl-menu-dropdown-min-width: #{$dx-menu-dropdown-min-width-sm};
        --dxbl-menu-item-image-size: #{$dx-menu-item-image-size-sm};
    }

    &.dxbl-lg {
        --dxbl-menu-item-line-height: #{$dx-menu-dropdown-item-line-height-lg};
        --dxbl-menu-item-font-size: #{$dx-menu-dropdown-item-font-size-lg};
        --dxbl-menu-item-image-spacing: #{$dx-menu-dropdown-item-image-spacing-lg};
        --dxbl-menu-item-padding-x: #{$dx-menu-dropdown-item-padding-x-lg};
        --dxbl-menu-item-padding-y: #{$dx-menu-dropdown-item-padding-y-lg};
        --dxbl-menu-dropdown-min-width: #{$dx-menu-dropdown-min-width-lg};
        --dxbl-menu-item-image-size: #{$dx-menu-item-image-size-lg};
    }

    &.dxbl-dropdown-dialog {
        --dxbl-dropdown-border-radius: var(--dxbl-menu-dropdown-border-radius);
        --dxbl-dropdown-border-width: var(--dxbl-menu-dropdown-border-width);
        --dxbl-dropdown-border-color: var(--dxbl-menu-dropdown-border-color);
        --dxbl-dropdown-border-style: var(--dxbl-menu-dropdown-border-style);
        --dxbl-dropdown-min-width: var(--dxbl-menu-dropdown-min-width);

        & > .dxbl-dropdown-body {
            --dxbl-dropdown-body-padding-x: var(--dxbl-menu-dropdown-padding-x);
            --dxbl-dropdown-body-padding-y: var(--dxbl-menu-dropdown-padding-y);

            .dxbl-menu-item-list {
                @extend %menu-item-list;

                flex-direction: column;
                width: 100%;

                &.dxbl-menu-nav-item-list {
                    display: block;
                }

                &:not(.dxbl-menu-nav-item-list) {
                    .dxbl-menu-item-text {
                        white-space: nowrap;
                    }
                }

                &.dxbl-menu-item-list-with-icons > .dxbl-menu-list-item > .dxbl-menu-item > .dxbl-btn {
                    & > .dxbl-menu-item-text-container:first-child,
                    & > .dxbl-menu-item-indent + .dxbl-menu-item-text-container {
                        @include menu-item-image-placeholder("margin-left", "--dxbl-menu-item-image-size", "--dxbl-menu-item-image-spacing");
                    }
                }

                & > .dxbl-menu-list-item {
                    &.dxbl-menu-dropdown-item {
                        display: flex;
                        width: 100%;
                        text-align: inherit;
                        text-decoration: none;
                        white-space: nowrap;
                    }

                    & > .dxbl-menu-item {
                        @extend %menu-item;

                        & > .dxbl-btn {
                            @include menu-item-btn(
                                "--dxbl-menu-font-family",
                                "--dxbl-menu-item-font-size",
                                "--dxbl-menu-item-line-height",
                                "--dxbl-menu-color",
                                "--dxbl-menu-item-padding-x",
                                "--dxbl-menu-item-padding-y",
                                "--dxbl-menu-item-image-spacing",
                                "--dxbl-menu-item-disabled-bg",
                                "--dxbl-menu-item-disabled-color",
                                "--dxbl-menu-item-disabled-opacity",
                                "--dxbl-menu-item-hover-bg",
                                "--dxbl-menu-item-hover-color",
                                "--dxbl-menu-item-selected-bg",
                                "--dxbl-menu-item-selected-color",
                                "--dxbl-menu-item-selected-hover-bg",
                                "--dxbl-menu-item-selected-hover-color"
                            );

                            & > .dxbl-menu-item-indent {
                                &:first-child {
                                    @include menu-item-image-placeholder("margin-left", "--dxbl-menu-item-image-size", "--dxbl-menu-item-image-spacing", "--dxbl-menu-item-indent-count");
                                }

                                & + .dxbl-image {
                                    margin-left: 0;
                                }

                                display: inline-block;
                                flex: 0 0 auto;

                                @include menu-item-image-placeholder("width", "--dxbl-menu-item-image-size", "--dxbl-menu-item-image-spacing", null, 0.5);
                            }

                            & > .dxbl-menu-item-text-container {
                                margin-right: auto;
                            }
                        }
                    }
                }

                & > .dxbl-menu-separator {
                    @include menu-items-separator("--dxbl-menu-separator-width", "--dxbl-menu-separator-style", "--dxbl-menu-separator-color", true);
                }
            }
        }
    }

    &:not(.dxbl-menu-navbar-dropdown) {
        .dxbl-menu-item-list {
            & > .dxbl-menu-list-item:first-child > .dxbl-menu-item > .dxbl-btn {
                border-top-left-radius: var(--dxbl-menu-dropdown-border-radius);
                border-top-right-radius: var(--dxbl-menu-dropdown-border-radius);
            }

            & > .dxbl-menu-list-item:last-child > .dxbl-menu-item > .dxbl-btn {
                border-bottom-left-radius: var(--dxbl-menu-dropdown-border-radius);
                border-bottom-right-radius: var(--dxbl-menu-dropdown-border-radius);
            }
        }
    }

    .dxbl-itemlist-dropdown[x-drop-alignment="bottom"] > &.dxbl-menu-navbar-dropdown {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        & > .dxbl-dropdown-body,
        .dxbl-menu-list-item:last-child {
            & > .dxbl-menu-nav-item-list > .dxbl-menu-list-item:last-child > .dxbl-menu-item:last-child > .dxbl-btn {
                border-bottom-left-radius: var(--dxbl-menu-dropdown-border-radius);
                border-bottom-right-radius: var(--dxbl-menu-dropdown-border-radius);
            }
        }
    }

    .dxbl-itemlist-dropdown[x-drop-alignment="top"] > &.dxbl-menu-navbar-dropdown {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        & > .dxbl-dropdown-body > .dxbl-menu-nav-item-list > .dxbl-menu-list-item:first-child > .dxbl-menu-item > .dxbl-btn {
            border-top-left-radius: var(--dxbl-menu-dropdown-border-radius);
            border-top-right-radius: var(--dxbl-menu-dropdown-border-radius);
        }
    }

    .dxbl-itemlist-dropdown:not([data-dx-focus-hidden]) > & {
        .dxbl-menu-item-list > .dxbl-menu-list-item > .dxbl-menu-item {
            & > .dxbl-btn,
            & > .dxbl-menu-item-tmpl {
                &:focus-visible {
                    outline: var(--dxbl-menu-dropdown-item-focus-outline-width) solid var(--dxbl-menu-dropdown-item-focus-outline-color);
                    outline-offset: calc(var(--dxbl-menu-dropdown-item-focus-outline-width) * -1);
                }
            }
        }
    }
}
