@import "mixins/common";

.dxbl-list-box {
    --dxbl-list-box-font-family: #{$dx-list-box-font-family};
    --dxbl-list-box-font-size: #{$dx-list-box-font-size};
    --dxbl-list-box-line-height: #{$dx-list-box-line-height};
    --dxbl-list-box-bg: #{$dx-list-box-bg};
    --dxbl-list-box-color: #{$dx-list-box-color};
    --dxbl-list-box-border-width: #{$dx-list-box-border-width};
    --dxbl-list-box-border-style: #{$dx-list-box-border-style};
    --dxbl-list-box-border-color: #{$dx-list-box-border-color};
    --dxbl-list-box-border-radius: #{$dx-list-box-border-radius};
    --dxbl-list-box-readonly-bg: #{$dx-list-box-disabled-bg};
    --dxbl-list-box-readonly-color: #{$dx-list-box-disabled-color};
    --dxbl-list-box-disabled-bg: #{$dx-list-box-disabled-bg};
    --dxbl-list-box-disabled-color: #{$dx-list-box-disabled-color};
    --dxbl-list-box-disabled-opacity: #{$dx-list-box-disabled-opacity};
    --dxbl-list-box-item-border-color: #{$dx-list-box-item-border-color};
    --dxbl-list-box-item-border-style: #{$dx-list-box-item-border-style};
    --dxbl-list-box-item-border-width: #{$dx-list-box-item-border-width};
    --dxbl-list-box-item-selected-bg: #{$dx-list-box-item-selected-bg};
    --dxbl-list-box-item-selected-color: #{$dx-list-box-item-selected-color};
    --dxbl-list-box-item-multi-selected-bg: #{$dx-list-box-item-multi-selected-bg};
    --dxbl-list-box-item-multi-selected-color: #{$dx-list-box-item-multi-selected-color};
    --dxbl-list-box-item-active-bg: #{$dx-list-box-item-active-bg};
    --dxbl-list-box-item-active-color: #{$dx-list-box-item-active-color};
    --dxbl-list-box-item-hover-bg: #{$dx-list-box-item-hover-bg};
    --dxbl-list-box-item-hover-color: #{$dx-list-box-item-hover-color};
    --dxbl-list-box-item-selected-hover-bg: #{$dx-list-box-item-selected-hover-bg};
    --dxbl-list-box-item-selected-hover-color: #{$dx-list-box-item-selected-hover-color};
    --dxbl-list-box-item-multi-selected-hover-bg: #{$dx-list-box-item-multi-selected-hover-bg};
    --dxbl-list-box-item-multi-selected-hover-color: #{$dx-list-box-item-multi-selected-hover-color};
    --dxbl-list-box-item-disabled-bg: #{$dx-list-box-item-disabled-bg};
    --dxbl-list-box-item-disabled-color: #{$dx-list-box-item-disabled-color};
    --dxbl-list-box-item-padding-x: #{$dx-list-box-item-padding-x};
    --dxbl-list-box-item-padding-y: #{$dx-list-box-item-padding-y};
    --dxbl-list-box-empty-item-padding-x: #{$dx-list-box-empty-item-padding-x};
    --dxbl-list-box-empty-item-padding-y: #{$dx-list-box-empty-item-padding-y};
    --dxbl-list-box-column-header-bg: #{$dx-list-box-column-header-bg};
    --dxbl-list-box-column-header-color: #{$dx-list-box-column-header-color};
    --dxbl-list-box-column-header-font-weight: #{$dx-list-box-column-header-font-weight};
    --dxbl-list-box-validation-valid-color: #{$dx-validation-valid-color};
    --dxbl-list-box-validation-invalid-color: #{$dx-validation-invalid-color};
    --dxbl-list-box-empty-data-icon-width: #{$dx-list-box-empty-data-icon-width};
    --dxbl-list-box-empty-data-icon-height: #{$dx-list-box-empty-data-icon-height};
    --dxbl-list-box-empty-data-icon-color: #{$dx-list-box-empty-data-icon-color};
    --dxbl-list-box-empty-data-font-weight: #{$dx-list-box-empty-data-font-weight};
    --dxbl-list-box-empty-data-text-color: #{$dx-list-box-empty-data-text-color};
    --dxbl-list-box-check-element-padding-right: #{$dx-list-box-check-element-padding-right};

    display: flex;
    flex-direction: column;
    font-family: var(--dxbl-list-box-font-family);
    font-size: var(--dxbl-list-box-font-size);
    line-height: var(--dxbl-list-box-line-height);
    background-color: var(--dxbl-list-box-bg);
    border: var(--dxbl-list-box-border-width) var(--dxbl-list-box-border-style) var(--dxbl-list-box-border-color);
    border-radius: var(--dxbl-list-box-border-radius);
    color: var(--dxbl-list-box-color);
    height: $dx-list-box-default-height;
    overflow-y: hidden;

    > .dxbl-scroll-viewer {
        background-color: var(--dxbl-list-box-bg);
        flex-grow: 1;

        > .dxbl-scroll-viewer-content,
        > .dxbl-scroll-viewer-content > table,
        > .dxbl-scroll-viewer-content > table > thead {
            background-color: inherit;
        }

        > .dxbl-scroll-viewer-content {
            overflow-anchor: none;

            > ul {
                display: flex;
                flex-direction: column;
                list-style-type: none;
                margin: 0;
                padding: 0;
                position: relative;
                z-index: 0;
                min-width: 100%;
                width: fit-content;

                > li:not([dxbl-bottom-virtual-spacer-element]):not([dxbl-top-virtual-spacer-element]) {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    padding: var(--dxbl-list-box-item-padding-y) var(--dxbl-list-box-item-padding-x);
                    border: var(--dxbl-list-box-item-border-width) var(--dxbl-list-box-item-border-style) var(--dxbl-list-box-item-border-color);

                    > .dxbl-checkbox {
                        --dxbl-checkbox-border-width: 0;

                        padding-right: var(--dxbl-list-box-check-element-padding-right);
                    }
                }

                > li[dxbl-bottom-virtual-spacer-element],
                > li[dxbl-top-virtual-spacer-element] {
                    > div {
                        position: sticky;
                        top: 0;
                        width: 100%;

                        > div {
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            padding: var(--dxbl-list-box-item-padding-y) var(--dxbl-list-box-item-padding-x);
                            border: var(--dxbl-list-box-item-border-width) var(--dxbl-list-box-item-border-style) var(--dxbl-list-box-item-border-color);
                        }
                    }
                }
            }

            > table {
                border-color: var(--dxbl-list-box-border-color);
                border-style: var(--dxbl-list-box-border-style);
                border-width: 0;
                border-spacing: 0;
                border-collapse: separate;
                table-layout: fixed;
                width: 100%;
                z-index: 0;
                position: relative;

                > thead,
                > tbody,
                > thead > tr,
                > tbody > tr,
                > thead > tr > th,
                > tbody > tr > td {
                    border-color: inherit;
                    border-style: inherit;
                    border-width: 0;
                }

                > thead > tr > th,
                > tbody > tr > td {
                    padding: var(--dxbl-list-box-item-padding-y) var(--dxbl-list-box-item-padding-x);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    overflow-wrap: normal;
                }

                > colgroup > col {
                    min-width: 50px;
                }

                > tbody {
                    > tr {
                        &:not(:first-child) > td {
                            border-top-width: var(--dxbl-list-box-border-width);
                        }

                        > td:not(:first-child) {
                            border-left-width: var(--dxbl-list-box-border-width);
                        }

                        > td.dxbl-list-box-selection-cell {
                            padding-top: 0;
                            padding-bottom: 0;
                            text-align: center;

                            > .dxbl-checkbox {
                                --dxbl-checkbox-border-width: 0;

                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }

                    > tr[dxbl-bottom-virtual-spacer-element],
                    > tr[dxbl-top-virtual-spacer-element] {
                        > td {
                            overflow: visible;
                            padding: 0;
                            vertical-align: top;
                            border-color: inherit;
                            border-style: inherit;

                            > div {
                                position: sticky;
                                top: 0;
                                border-color: inherit;
                                border-style: inherit;
                                border-width: 0;

                                > div {
                                    border-color: inherit;
                                    border-style: inherit;
                                    border-width: 0;
                                    padding: var(--dxbl-list-box-item-padding-y) var(--dxbl-list-box-item-padding-x);
                                }
                            }
                        }
                    }

                    > tr[dxbl-top-virtual-spacer-element] + tr:not([dxbl-top-virtual-spacer-element]),
                    > tr[dxbl-bottom-virtual-spacer-element] {
                        > td {
                            border-top-width: 0;
                        }
                    }

                    > tr[dxbl-top-virtual-spacer-element] > td > div > div {
                        &:last-child {
                            border-bottom-width: var(--dxbl-list-box-border-width);
                        }

                        &:not(:first-child) {
                            border-top-width: var(--dxbl-list-box-border-width);
                        }
                    }

                    > tr[dxbl-bottom-virtual-spacer-element] > td > div > div {
                        border-top-width: var(--dxbl-list-box-border-width);
                    }
                }

                > thead {
                    background-color: inherit;
                    top: 0;
                    position: sticky;
                    z-index: 2;

                    > tr {
                        > th {
                            font-weight: var(--dxbl-list-box-column-header-font-weight);
                            border-bottom-width: var(--dxbl-list-box-border-width);
                            cursor: default;

                            @include panel-header-style("--dxbl-list-box-column-header-bg", "--dxbl-list-box-column-header-color");

                            &:not(:first-child) {
                                border-left-width: var(--dxbl-list-box-border-width);
                            }
                        }
                    }
                }
            }

            > ul > li.dxbl-list-box-item-selected,
            > table > tbody > tr.dxbl-list-box-item-selected > td {
                @include state-selected-primary-style("--dxbl-list-box-item-selected-bg", "--dxbl-list-box-item-selected-color", "--dxbl-list-box-item-border-width");
            }

            > .dxbl-list-box-empty-data-container {
                &,
                > li:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]) {
                    height: 100%;
                }

                > li:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]),
                > tbody > tr:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]) > td {
                    border: 0;
                    padding: 0;

                    > div {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:not(.dxbl-list-box-empty-data-area-template-container) {
                            flex-direction: column;

                            > span {
                                font-weight: var(--dxbl-list-box-empty-data-font-weight);
                                padding: var(--dxbl-list-box-item-padding-y) 0;
                                color: var(--dxbl-list-box-empty-data-text-color);
                            }

                            > .dxbl-image {
                                width: var(--dxbl-list-box-empty-data-icon-width);
                                height: var(--dxbl-list-box-empty-data-icon-height);
                                color: var(--dxbl-list-box-empty-data-icon-color);
                            }
                        }
                    }
                }

                // stylelint-disable selector-class-pattern

                .dxFirefox & {
                    > thead {
                        height: 1%;
                    }

                    > tbody > tr > td {
                        height: fit-content;
                    }
                }

                // stylelint-enable selector-class-pattern
            }

            > :not(.dxbl-list-box-empty-data-container) {
                > li:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]):not(.dxbl-skeleton-item) {
                    > div {
                        width: 100%;
                    }
                }
            }

            > table.dxbl-list-box-table-no-scroll:not(.dxbl-list-box-empty-data-container) > tbody {
                &:not([data-virtual-items-container]) > tr:last-child,
                &[data-virtual-items-container] > tr:nth-last-child(-n+2):not([dxbl-bottom-virtual-spacer-element]) {
                    > td {
                        border-bottom-width: var(--dxbl-list-box-border-width);
                    }
                }
            }
        }
    }

    &.dxbl-list-box-checked {
        > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content {
            > ul > li:not([dxbl-bottom-virtual-spacer-element]):not([dxbl-top-virtual-spacer-element]):not(.dxbl-skeleton-item) {
                --dxbl-list-box-item-padding-y: 0;
            }
        }
    }

    &.dxbl-list-box-multi-select {
        > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content {
            > ul > li.dxbl-list-box-item-selected,
            > table > tbody > tr.dxbl-list-box-item-selected > td {
                @include state-selected-style("--dxbl-list-box-item-multi-selected-bg", "--dxbl-list-box-item-multi-selected-color", "--dxbl-list-box-item-border-width");
            }
        }
    }

    @include not-disabled-state() {
        @include not-readonly-state() {
            > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > :not(.dxbl-list-box-empty-data-container) {
                > li,
                > tbody > tr {
                    cursor: pointer;
                    pointer-events: auto;
                }

                > li:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]):hover,
                > tbody > tr:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]):hover > td {
                    @include state-hover-style("--dxbl-list-box-item-hover-bg", "--dxbl-list-box-item-hover-color", "--dxbl-list-box-item-border-width");
                }

                > li.dxbl-list-box-item-selected:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]):hover,
                > tbody > tr.dxbl-list-box-item-selected:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]):hover > td {
                    @include state-selected-primary-hover-style("--dxbl-list-box-item-selected-hover-bg", "--dxbl-list-box-item-selected-hover-color", "--dxbl-list-box-item-border-width");
                }
            }

            &.dxbl-list-box-multi-select > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > :not(.dxbl-list-box-empty-data-container) {
                > li.dxbl-list-box-item-selected:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]):hover,
                > tbody > tr.dxbl-list-box-item-selected:not([dxbl-top-virtual-spacer-element]):not([dxbl-bottom-virtual-spacer-element]):hover > td {
                    @include state-selected-hover-style("--dxbl-list-box-item-multi-selected-hover-bg", "--dxbl-list-box-item-multi-selected-hover-color", "--dxbl-list-box-item-border-width");
                }
            }
        }
    }

    @include readonly-state() {
        > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content {
            > ul,
            > table > tbody {
                @include state-disabled-style("--dxbl-list-box-readonly-bg", "--dxbl-list-box-readonly-color");
            }
        }
    }

    @include disabled-state() {
        > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content {
            > ul,
            > table > tbody {
                @include state-disabled-style("--dxbl-list-box-disabled-bg", "--dxbl-list-box-disabled-color");
            }

            > ul,
            > table {
                opacity: var(--dxbl-list-box-disabled-opacity);
            }
        }
    }

    @include valid-state() {
        --dxbl-list-box-border-color: var(--dxbl-list-box-validation-valid-color);
    }

    @include invalid-state() {
        --dxbl-list-box-border-color: var(--dxbl-list-box-validation-invalid-color);
    }

    &.dxbl-sm {
        --dxbl-list-box-font-size: #{$dx-list-box-font-size-sm};
        --dxbl-list-box-line-height: #{$dx-list-box-line-height-sm};
        --dxbl-list-box-border-radius: #{$dx-list-box-border-radius-sm};
        --dxbl-list-box-item-padding-x: #{$dx-list-box-item-padding-x-sm};
        --dxbl-list-box-item-padding-y: #{$dx-list-box-item-padding-y-sm};
        --dxbl-list-box-empty-item-padding-x: #{$dx-list-box-empty-item-padding-x-sm};
        --dxbl-list-box-empty-item-padding-y: #{$dx-list-box-empty-item-padding-y-sm};
        --dxbl-list-box-check-element-padding-right: #{$dx-list-box-check-element-padding-right-sm};

        li > div[dxbl-skeleton-items-container] > .dxbl-skeleton-item,
        li.dxbl-skeleton-item {
            > .dxbl-skeleton-checkbox {
                width: #{$dx-checkbox-check-element-size-sm};
            }
        }
    }

    &.dxbl-lg {
        --dxbl-list-box-font-size: #{$dx-list-box-font-size-lg};
        --dxbl-list-box-line-height: #{$dx-list-box-line-height-lg};
        --dxbl-list-box-border-radius: #{$dx-list-box-border-radius-lg};
        --dxbl-list-box-item-padding-x: #{$dx-list-box-item-padding-x-lg};
        --dxbl-list-box-item-padding-y: #{$dx-list-box-item-padding-y-lg};
        --dxbl-list-box-empty-item-padding-x: #{$dx-list-box-empty-item-padding-x-lg};
        --dxbl-list-box-empty-item-padding-y: #{$dx-list-box-empty-item-padding-y-lg};
        --dxbl-list-box-check-element-padding-right: #{$dx-list-box-check-element-padding-right-lg};

        li > div[dxbl-skeleton-items-container] > .dxbl-skeleton-item,
        li.dxbl-skeleton-item {
            > .dxbl-skeleton-checkbox {
                width: #{$dx-checkbox-check-element-size-lg};
            }
        }
    }

    .dxbl-skeleton-item {
        > .dxbl-skeleton-checkbox {
            clip-path: circle();
        }
    }

    li > div[dxbl-skeleton-items-container] > .dxbl-skeleton-item,
    li.dxbl-skeleton-item {
        > .dxbl-skeleton-checkbox {
            margin-right: var(--dxbl-list-box-check-element-padding-right);
            width: #{$dx-checkbox-check-element-size};
        }
    }

    &.dxbl-list-box-virtual-spacer-pattern-list-container {
        position: fixed;
        top: -10000px;
        left: -10000px;
        visibility: hidden;

        & > div {
            padding: var(--dxbl-list-box-item-padding-y) var(--dxbl-list-box-item-padding-x);
            border: var(--dxbl-list-box-item-border-width) var(--dxbl-list-box-item-border-style) var(--dxbl-list-box-item-border-color);
        }
    }

    &.dxbl-list-box-virtual-spacer-pattern-table-container {
        position: fixed;
        top: -10000px;
        left: -10000px;
        visibility: hidden;

        & > div {
            border-color: var(--dxbl-list-box-border-color);
            border-style: var(--dxbl-list-box-border-style);
            border-spacing: 0;
            padding: var(--dxbl-list-box-item-padding-y) var(--dxbl-list-box-item-padding-x);
            border-width: var(--dxbl-list-box-border-width) 0 0 0;
        }
    }
}
