
.dxbs-uc .progress {
    height: 1rem
}
.dxbs-uc .dxuc-file-list-view .progress {
    height: 0.4375rem;
}
.dxbs-uc .progress-bar {
    -webkit-transition: width .1s ease;
    -o-transition: width .1s ease;
    transition: width .1s ease;
}
.dxbs-uc .dxuc-button-container {
    height: 3rem;
}

.dxbs-uc > .row > .col > .dxuc-file-view td {
    border: none !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.dxbs-uc > .dxuc-file-list-view .dxuc-file-view {
    width: 100%;
    table-layout: fixed;

    & > colgroup.dxbl-upload-file-view-table-content-fit-calc {
        & > col:first-of-type {
            width: 0;
        }

        @media (min-width: 600px) {
            & > col:first-of-type {
                width: 72px;
            }
        }

        & > col:nth-child(2) {
            width: 100%;
        }

        & > col:last-of-type {
            width: 72px;
        }
    }

    .dxbl-upload-file-view-icon-container {
        padding: 0.5rem 0.75rem;

        & > .dxbl-upload-file-view-icon-container-file-extension.badge {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 1.75rem 0.5rem 1rem;
            padding: 0.25em 0.4em;
            font-size: 11px;
            max-width: calc(100% - 1rem);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    td {
        border: none !important;
        padding-top: 5px;
        padding-bottom: 5px;

        & > .dxbl-upload-file-view-details {
            flex-wrap: nowrap;

            & > .dxbl-upload-file-view-details-text {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}

.dxbs-uc > .dxuc-max-file-count-exceeded-warning {
    & > .dxuc-max-file-count-exceeded-warning-message {
        display: flex;
        justify-content: center;
        flex: 1;
        margin: 0.5rem;
    }

    & > .dxbl-image {
        align-self: flex-start;
        margin: 0.625rem 0 0;
    }

    & > .dxbl-btn {
        align-self: flex-start;
        margin: 0.5rem 0;
    }

    & > .dxbl-btn {
        & > .dxbl-image {
            margin: 0;
        }

        padding: 0;
        border: 0;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--bs-warning);
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    margin-top: 1rem;
    width: 100%;
}

.dxuc-button-icon-container {
    position: relative;
    z-index: 1;
    color: inherit;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
}
.dxuc-button-icon-container:hover:before {
    content: " ";
    position: absolute;
    z-index: -1;
    background-color: currentColor;
    right: 2px;
    opacity: 0.1;
    border-radius: 100%;
    width: 20px;
    height: 20px;
}

.dxuc-hide,
.dxbl-upload-file-view-icon-container {
    display: none;
}

@media (min-width: 600px) {
    .dxbl-upload-file-view-icon-container {
        display: inherit;
    }
}

.dxuc-cancel-icon,
.dxuc-pause-icon,
.dxuc-reload-icon,
.dxuc-continue-icon,
.dxuc-upload-icon {
    cursor: pointer;
}

.dxbl-image.dxuc-file-icon {
    width: 48px;
    height: 48px;
}
