@import "_chart.legend";

@keyframes dx-rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.dx-chart-title {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
}

.dx-chart-subtitle {
    font-size: 0.8em;
    font-weight: 300;
}
.dx-blazor-widget .dx-chart {
    overflow: hidden;
    flex: 1 1 auto;
    padding: 0 0.75rem;
}
.dx-blazor-widget {
    background-color: unset;
    position: relative;
}
.dx-blazor-widget.dx-loading .dx-chart-with-legend-container {
    position: relative;
}
.dx-blazor-widget .dx-chart-with-legend-container {
    height: 100%;
    width: 100%;
}
.dx-blazor-widget.dx-loading .dx-chart > svg {
    visibility: hidden;
    opacity: 0;
}
.dx-blazor-widget.dx-loading .dx-chart::before {
    content: " ";
    display: block;
    position: absolute;
    top: 1em;
    left: 1em;
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    border-radius: 0.5em;
    opacity: 0.025;
}

.dx-blazor-widget.dx-loading .dx-chart::after {
    content: " ";
    display: block;
    position: absolute;
    top: calc(50% - 2em);
    left: calc(50% - 2em);
    width: 4em;
    height: 4em;
    border-radius: 50%;
    border: 0.2em solid currentColor;
    border-bottom-color: transparent;
    animation: dx-rotating 1s linear infinite;
}
$tooltip-border: 1px solid #d1d1d1;

.dx-chart-tooltip.show {
    visibility: visible;
}
.dx-chart-tooltip {
    visibility: hidden;
    display: block;
    z-index: 1;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -100%);
    position: absolute;
    will-change: filter;
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.53));
    -webkit-filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.53));

    > .form-control {
        width: initial;
        height: initial;
        padding: 0;
        white-space: nowrap;
        border: $tooltip-border;
        border-radius: 3px;
        color: var(--bs-gray-900, #212529);

        + .dx-tooltip-pointer:after {
            width: 0.6275rem;
            height: 0.6275rem;
            transform-origin: center;
            transform: translate(-50%, -50%) rotate(45deg);
            content: " ";
            background-color: inherit;
            position: absolute;
            border-bottom: $tooltip-border;
            border-right: $tooltip-border;
            border-top: 1px solid transparent;
            border-left: 1px solid transparent;
        }

        + .dx-tooltip-pointer {
            top: calc(100% - 0.1px);
            position: absolute;
        }

        .dx-tooltip-content.default {
            padding: 0.75rem;
        }
    }
}
