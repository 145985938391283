
/**
 * Do not edit directly
 * Generated on Wed, 06 Dec 2023 07:55:29 GMT
 */

$dx-gen-text-decoration-button-link-underline: Underline !default;
$dx-gen-typography-button-text-none-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-none-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-none-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-none-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-none-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-none-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-none-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-none-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-none-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-none-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-none-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-none-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-none-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-none-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-none-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-link-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-link-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-link-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-link-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-link-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-link-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-link-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-link-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-link-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-link-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-link-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-link-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-link-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-link-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-link-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-light-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-light-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-light-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-light-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-light-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-light-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-light-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-light-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-light-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-light-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-light-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-light-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-light-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-light-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-light-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-dark-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-dark-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-dark-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-dark-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-dark-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-dark-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-dark-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-dark-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-dark-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-dark-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-dark-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-dark-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-dark-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-dark-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-dark-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-danger-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-danger-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-danger-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-danger-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-danger-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-danger-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-danger-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-danger-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-danger-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-danger-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-danger-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-danger-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-danger-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-danger-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-danger-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-warning-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-warning-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-warning-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-warning-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-warning-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-warning-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-warning-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-warning-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-warning-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-warning-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-warning-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-warning-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-warning-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-warning-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-warning-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-success-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-success-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-success-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-success-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-success-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-success-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-success-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-success-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-success-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-success-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-success-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-success-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-success-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-success-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-success-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-info-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-info-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-info-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-info-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-info-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-info-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-info-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-info-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-info-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-info-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-info-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-info-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-info-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-info-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-info-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-secondary-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-secondary-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-secondary-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-secondary-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-secondary-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-secondary-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-secondary-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-secondary-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-secondary-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-secondary-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-secondary-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-secondary-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-secondary-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-secondary-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-secondary-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-primary-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-primary-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-primary-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-text-primary-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-primary-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-primary-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-primary-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-primary-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-text-primary-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-primary-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-text-primary-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-text-primary-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-text-primary-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-text-primary-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-text-primary-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-none-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-none-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-none-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-none-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-none-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-none-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-none-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-none-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-none-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-none-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-none-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-none-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-none-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-none-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-none-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-link-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-link-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-link-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-link-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-link-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-link-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-link-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-link-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-link-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-link-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-link-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-link-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-link-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-link-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-link-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-light-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-light-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-light-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-light-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-light-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-light-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-light-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-light-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-light-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-light-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-light-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-light-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-light-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-light-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-light-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-dark-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-dark-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-dark-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-dark-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-dark-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-dark-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-dark-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-dark-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-dark-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-dark-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-dark-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-dark-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-dark-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-dark-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-dark-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-danger-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-danger-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-danger-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-danger-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-danger-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-danger-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-danger-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-danger-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-danger-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-danger-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-danger-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-danger-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-danger-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-danger-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-danger-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-warning-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-warning-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-warning-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-warning-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-warning-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-warning-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-warning-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-warning-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-warning-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-warning-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-warning-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-warning-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-warning-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-warning-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-warning-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-success-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-success-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-success-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-success-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-success-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-success-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-success-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-success-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-success-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-success-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-success-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-success-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-success-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-success-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-success-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-info-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-info-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-info-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-info-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-info-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-info-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-info-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-info-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-info-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-info-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-info-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-info-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-info-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-info-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-info-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-secondary-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-secondary-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-secondary-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-secondary-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-secondary-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-secondary-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-secondary-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-secondary-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-secondary-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-secondary-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-secondary-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-secondary-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-secondary-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-secondary-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-secondary-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-primary-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-primary-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-primary-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-outline-primary-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-primary-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-primary-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-primary-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-primary-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-outline-primary-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-primary-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-outline-primary-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-outline-primary-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-outline-primary-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-outline-primary-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-outline-primary-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-none-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-none-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-none-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-none-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-none-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-none-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-none-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-none-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-none-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-none-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-none-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-none-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-none-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-none-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-none-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-link-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-link-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-link-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-link-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-link-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-link-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-link-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-link-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-link-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-link-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-link-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-link-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-link-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-link-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-link-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-light-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-light-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-light-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-light-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-light-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-light-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-light-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-light-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-light-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-light-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-light-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-light-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-light-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-light-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-light-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-dark-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-dark-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-dark-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-dark-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-dark-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-dark-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-dark-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-dark-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-dark-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-dark-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-dark-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-dark-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-dark-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-dark-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-dark-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-danger-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-danger-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-danger-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-danger-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-danger-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-danger-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-danger-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-danger-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-danger-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-danger-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-danger-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-danger-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-danger-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-danger-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-danger-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-warning-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-warning-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-warning-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-warning-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-warning-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-warning-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-warning-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-warning-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-warning-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-warning-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-warning-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-warning-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-warning-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-warning-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-warning-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-success-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-success-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-success-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-success-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-success-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-success-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-success-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-success-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-success-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-success-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-success-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-success-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-success-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-success-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-success-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-info-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-info-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-info-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-info-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-info-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-info-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-info-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-info-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-info-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-info-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-info-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-info-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-info-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-info-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-info-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-secondary-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-secondary-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-secondary-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-secondary-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-secondary-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-secondary-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-secondary-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-secondary-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-secondary-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-secondary-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-secondary-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-secondary-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-secondary-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-secondary-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-secondary-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-primary-large-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-primary-large-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-primary-large-line-height: $dx-gen-line-height-xl !default;
$dx-gen-typography-button-contained-primary-large-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-primary-large-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-primary-medium-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-primary-medium-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-primary-medium-line-height: $dx-gen-line-height-l !default;
$dx-gen-typography-button-contained-primary-medium-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-primary-medium-font-family: $dx-gen-sans-serif !default;
$dx-gen-typography-button-contained-primary-small-text-case: $dx-gen-text-transform-normal !default;
$dx-gen-typography-button-contained-primary-small-letter-spacing: $dx-gen-letter-spacing-normal !default;
$dx-gen-typography-button-contained-primary-small-line-height: $dx-gen-line-height-m !default;
$dx-gen-typography-button-contained-primary-small-font-weight: $dx-gen-font-weight-400 !default;
$dx-gen-typography-button-contained-primary-small-font-family: $dx-gen-sans-serif !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-light-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-dark-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-danger-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-warning-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-success-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-info-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-secondary-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-icon-primary-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-light-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-dark-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-danger-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-warning-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-success-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-info-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-secondary-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-outline-primary-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-light-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-dark-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-danger-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-warning-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-success-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-info-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-secondary-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-active-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-focused-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-hover-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-large-vertical-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-large-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-1-5 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-icon-button-contained-primary-normal-inner-box-small-horizontal-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-none-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-none-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-none-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-none-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-none-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-none-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-none-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-none-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-none-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-none-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-none-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-none-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-none-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-none-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-none-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-none-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-none-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-none-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-none-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-none-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-none-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-link-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-link-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-link-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-link-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-link-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-link-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-link-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-link-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-link-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-link-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-link-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-link-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-link-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-link-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-link-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-link-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-link-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-link-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-link-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-link-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-link-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-light-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-light-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-light-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-light-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-light-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-light-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-light-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-light-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-light-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-light-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-light-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-light-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-light-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-light-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-light-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-light-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-light-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-light-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-light-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-light-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-light-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-dark-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-dark-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-dark-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-dark-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-dark-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-dark-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-dark-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-dark-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-dark-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-dark-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-dark-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-dark-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-dark-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-dark-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-dark-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-danger-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-danger-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-danger-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-danger-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-danger-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-danger-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-danger-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-danger-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-danger-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-danger-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-danger-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-danger-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-danger-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-danger-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-danger-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-warning-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-warning-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-warning-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-warning-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-warning-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-warning-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-warning-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-warning-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-warning-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-warning-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-warning-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-warning-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-warning-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-warning-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-warning-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-success-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-success-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-success-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-success-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-success-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-success-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-success-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-success-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-success-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-success-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-success-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-success-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-success-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-success-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-success-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-success-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-success-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-success-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-success-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-success-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-success-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-info-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-info-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-info-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-info-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-info-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-info-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-info-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-info-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-info-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-info-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-info-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-info-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-info-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-info-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-info-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-info-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-info-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-info-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-info-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-info-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-info-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-secondary-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-secondary-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-secondary-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-secondary-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-secondary-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-secondary-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-secondary-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-secondary-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-primary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-primary-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-primary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-primary-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-primary-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-primary-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-primary-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-primary-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-text-primary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-primary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-primary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-primary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-text-primary-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-text-primary-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-text-primary-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-none-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-none-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-none-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-none-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-none-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-none-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-none-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-none-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-none-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-none-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-none-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-none-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-none-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-none-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-none-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-link-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-link-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-link-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-link-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-link-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-link-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-link-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-link-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-link-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-link-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-link-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-link-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-link-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-link-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-link-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-light-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-light-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-light-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-light-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-light-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-light-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-light-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-light-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-light-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-light-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-light-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-light-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-light-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-light-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-light-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-dark-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-dark-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-dark-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-dark-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-dark-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-dark-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-dark-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-dark-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-danger-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-danger-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-danger-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-danger-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-danger-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-danger-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-danger-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-danger-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-warning-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-warning-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-warning-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-warning-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-warning-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-warning-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-warning-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-warning-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-success-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-success-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-success-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-success-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-success-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-success-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-success-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-success-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-success-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-success-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-success-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-success-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-success-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-success-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-success-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-info-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-info-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-info-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-info-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-info-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-info-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-info-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-info-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-info-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-info-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-info-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-info-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-info-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-info-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-info-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-secondary-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-secondary-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-secondary-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-secondary-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-secondary-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-secondary-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-secondary-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-secondary-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-primary-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-primary-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-primary-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-primary-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-primary-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-outline-primary-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-outline-primary-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-outline-primary-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-none-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-none-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-none-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-none-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-none-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-none-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-none-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-none-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-none-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-none-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-none-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-none-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-none-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-none-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-none-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-link-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-link-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-link-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-link-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-link-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-link-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-link-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-link-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-link-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-link-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-link-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-link-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-link-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-link-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-link-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-light-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-light-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-light-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-light-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-light-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-light-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-light-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-light-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-light-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-light-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-light-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-light-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-light-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-light-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-light-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-dark-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-dark-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-dark-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-dark-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-dark-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-dark-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-dark-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-dark-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-danger-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-danger-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-danger-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-danger-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-danger-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-danger-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-danger-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-danger-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-warning-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-warning-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-warning-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-warning-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-warning-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-warning-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-warning-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-warning-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-success-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-success-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-success-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-success-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-success-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-success-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-success-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-success-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-success-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-success-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-success-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-success-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-success-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-success-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-success-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-info-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-info-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-info-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-info-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-info-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-info-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-info-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-info-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-info-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-info-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-info-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-info-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-info-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-info-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-info-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-secondary-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-secondary-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-secondary-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-secondary-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-secondary-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-secondary-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-secondary-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-secondary-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-disabled-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-disabled-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-disabled-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-disabled-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-disabled-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-disabled-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-disabled-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-active-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-active-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-active-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-active-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-active-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-active-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-active-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-focused-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-focused-outer-box-large-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-primary-focused-outer-box-large-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-primary-focused-outer-box-medium-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-primary-focused-outer-box-medium-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-primary-focused-outer-box-small-horizontal-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-primary-focused-outer-box-small-vertical-padding: $dx-gen-size-px !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-hover-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-hover-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-hover-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-hover-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-hover-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-hover-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-hover-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-large-item-spacing: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-large-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-large-horizontal-padding: $dx-gen-size-4 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-medium-item-spacing: $dx-gen-size-1-5 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-medium-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-medium-horizontal-padding: $dx-gen-size-3 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-small-item-spacing: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-small-vertical-padding: $dx-gen-size-1 !default;
$dx-gen-composition-button-contained-primary-normal-inner-box-small-horizontal-padding: $dx-gen-size-2 !default;
$dx-gen-composition-button-contained-primary-normal-outer-box-large-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-normal-outer-box-large-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-normal-outer-box-medium-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-normal-outer-box-medium-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-normal-outer-box-small-horizontal-padding: $dx-gen-size-0 !default;
$dx-gen-composition-button-contained-primary-normal-outer-box-small-vertical-padding: $dx-gen-size-0 !default;
$dx-gen-border-width-button-text-none-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-none-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-link-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-light-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-dark-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-danger-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-warning-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-success-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-info-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-secondary-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-text-primary-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-none-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-link-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-light-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-dark-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-danger-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-warning-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-success-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-info-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-secondary-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-outline-primary-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-none-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-link-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-light-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-dark-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-danger-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-warning-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-success-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-info-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-secondary-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-disabled-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-disabled-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-disabled-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-active-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-active-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-active-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-hover-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-hover-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-hover-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-normal-outer-box-large: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-normal-outer-box-medium: $dx-gen-border-width-0 !default;
$dx-gen-border-width-button-contained-primary-normal-outer-box-small: $dx-gen-border-width-0 !default;
$dx-gen-box-shadow-button-text-none-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-none-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-none-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-none-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-none-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-link-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-link-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-link-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-link-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-link-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-light-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-light-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-light-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-light-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-light-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-dark-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-dark-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-dark-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-dark-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-dark-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-danger-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-danger-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-danger-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-danger-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-danger-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-warning-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-warning-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-warning-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-warning-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-warning-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-success-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-success-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-success-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-success-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-success-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-info-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-info-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-info-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-info-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-info-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-secondary-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-secondary-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-secondary-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-secondary-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-secondary-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-primary-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-primary-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-primary-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-primary-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-text-primary-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-none-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-none-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-none-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-none-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-none-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-link-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-link-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-link-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-link-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-link-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-light-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-light-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-light-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-light-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-light-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-dark-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-dark-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-dark-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-dark-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-dark-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-danger-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-danger-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-danger-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-danger-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-danger-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-warning-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-warning-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-warning-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-warning-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-warning-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-success-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-success-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-success-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-success-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-success-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-info-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-info-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-info-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-info-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-info-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-secondary-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-secondary-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-secondary-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-secondary-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-secondary-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-primary-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-primary-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-primary-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-primary-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-outline-primary-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-none-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-none-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-none-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-none-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-none-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-link-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-link-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-link-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-link-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-link-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-light-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-dark-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-dark-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-dark-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-dark-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-dark-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-danger-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-danger-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-danger-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-danger-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-danger-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-warning-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-warning-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-warning-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-warning-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-warning-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-success-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-success-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-success-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-success-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-success-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-info-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-info-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-info-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-info-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-info-normal: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-secondary-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-primary-disabled: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-primary-active: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-primary-focused: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-primary-hover: $dx-gen-box-shadow-none !default;
$dx-gen-box-shadow-button-contained-primary-normal: $dx-gen-box-shadow-none !default;
$dx-gen-opacity-button-text-none-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-none-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-link-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-light-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-dark-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-danger-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-warning-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-success-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-info-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-secondary-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-text-primary-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-none-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-link-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-light-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-dark-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-danger-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-warning-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-success-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-info-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-secondary-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-outline-primary-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-none-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-link-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-light-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-dark-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-danger-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-warning-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-success-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-info-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-secondary-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-disabled-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-disabled-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-active-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-active-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-focused-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-focused-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-hover-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-hover-text: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-normal-icon: $dx-gen-opacity-100 !default;
$dx-gen-opacity-button-contained-primary-normal-text: $dx-gen-opacity-100 !default;
$dx-gen-typography-button-text-none-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-none-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-none-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-link-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-link-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-link-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-light-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-light-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-light-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-dark-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-dark-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-dark-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-danger-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-danger-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-danger-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-warning-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-warning-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-warning-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-success-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-success-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-success-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-info-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-info-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-info-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-secondary-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-secondary-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-secondary-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-text-primary-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-text-primary-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-text-primary-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-none-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-none-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-none-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-link-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-link-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-link-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-light-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-light-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-light-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-dark-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-dark-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-dark-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-danger-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-danger-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-danger-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-warning-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-warning-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-warning-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-success-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-success-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-success-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-info-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-info-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-info-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-secondary-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-secondary-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-secondary-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-outline-primary-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-outline-primary-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-outline-primary-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-none-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-none-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-none-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-link-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-link-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-link-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-light-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-light-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-light-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-dark-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-dark-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-dark-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-danger-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-danger-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-danger-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-warning-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-warning-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-warning-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-success-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-success-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-success-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-info-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-info-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-info-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-secondary-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-secondary-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-secondary-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-typography-button-contained-primary-large-font-size: $dx-gen-font-size-m !default;
$dx-gen-typography-button-contained-primary-medium-font-size: $dx-gen-font-size-s !default;
$dx-gen-typography-button-contained-primary-small-font-size: $dx-gen-font-size-xs !default;
$dx-gen-color-button-text-none-disabled-icon: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-text-none-disabled-text: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-text-none-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-active-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-none-active-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-none-active-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-active-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-none-focused-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-none-focused-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-none-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-hover-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-none-hover-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-none-hover-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-hover-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-normal-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-none-normal-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-none-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-none-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-disabled-icon: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-text-link-disabled-text: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-text-link-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-active-icon: $dx-gen-primary-600 !default;
$dx-gen-color-button-text-link-active-text: $dx-gen-primary-600 !default;
$dx-gen-color-button-text-link-active-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-active-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-link-focused-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-link-focused-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-link-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-hover-icon: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-link-hover-text: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-link-hover-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-hover-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-normal-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-link-normal-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-link-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-link-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-light-disabled-icon: $dx-gen-light-dark-50 !default;
$dx-gen-color-button-text-light-disabled-text: $dx-gen-light-dark-50 !default;
$dx-gen-color-button-text-light-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-light-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-light-active-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-light-active-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-light-active-border: $dx-gen-light-dark-200 !default;
$dx-gen-color-button-text-light-active-bg: $dx-gen-light-dark-200 !default;
$dx-gen-color-button-text-light-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-light-focused-icon: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-text-light-focused-text: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-text-light-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-light-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-light-hover-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-light-hover-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-light-hover-border: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-text-light-hover-bg: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-text-light-normal-icon: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-text-light-normal-text: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-text-light-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-light-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-dark-disabled-icon: $dx-gen-light-dark-300 !default;
$dx-gen-color-button-text-dark-disabled-text: $dx-gen-light-dark-300 !default;
$dx-gen-color-button-text-dark-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-dark-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-dark-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-dark-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-dark-active-border: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-dark-active-bg: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-dark-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-dark-focused-icon: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-text-dark-focused-text: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-text-dark-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-dark-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-dark-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-dark-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-dark-hover-border: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-text-dark-hover-bg: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-text-dark-normal-icon: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-text-dark-normal-text: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-text-dark-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-dark-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-danger-disabled-icon: $dx-gen-danger-300 !default;
$dx-gen-color-button-text-danger-disabled-text: $dx-gen-danger-300 !default;
$dx-gen-color-button-text-danger-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-danger-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-danger-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-danger-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-danger-active-border: $dx-gen-danger-600 !default;
$dx-gen-color-button-text-danger-active-bg: $dx-gen-danger-600 !default;
$dx-gen-color-button-text-danger-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-danger-focused-icon: $dx-gen-danger-400 !default;
$dx-gen-color-button-text-danger-focused-text: $dx-gen-danger-400 !default;
$dx-gen-color-button-text-danger-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-danger-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-danger-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-danger-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-danger-hover-border: $dx-gen-danger-500 !default;
$dx-gen-color-button-text-danger-hover-bg: $dx-gen-danger-500 !default;
$dx-gen-color-button-text-danger-normal-icon: $dx-gen-danger-400 !default;
$dx-gen-color-button-text-danger-normal-text: $dx-gen-danger-400 !default;
$dx-gen-color-button-text-danger-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-danger-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-warning-disabled-icon: $dx-gen-warning-300 !default;
$dx-gen-color-button-text-warning-disabled-text: $dx-gen-warning-300 !default;
$dx-gen-color-button-text-warning-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-warning-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-warning-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-warning-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-warning-active-border: $dx-gen-warning-600 !default;
$dx-gen-color-button-text-warning-active-bg: $dx-gen-warning-600 !default;
$dx-gen-color-button-text-warning-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-warning-focused-icon: $dx-gen-warning-400 !default;
$dx-gen-color-button-text-warning-focused-text: $dx-gen-warning-400 !default;
$dx-gen-color-button-text-warning-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-warning-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-warning-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-warning-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-warning-hover-border: $dx-gen-warning-500 !default;
$dx-gen-color-button-text-warning-hover-bg: $dx-gen-warning-500 !default;
$dx-gen-color-button-text-warning-normal-icon: $dx-gen-warning-400 !default;
$dx-gen-color-button-text-warning-normal-text: $dx-gen-warning-400 !default;
$dx-gen-color-button-text-warning-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-warning-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-success-disabled-icon: $dx-gen-success-300 !default;
$dx-gen-color-button-text-success-disabled-text: $dx-gen-success-300 !default;
$dx-gen-color-button-text-success-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-success-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-success-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-success-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-success-active-border: $dx-gen-success-600 !default;
$dx-gen-color-button-text-success-active-bg: $dx-gen-success-600 !default;
$dx-gen-color-button-text-success-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-success-focused-icon: $dx-gen-success-400 !default;
$dx-gen-color-button-text-success-focused-text: $dx-gen-success-400 !default;
$dx-gen-color-button-text-success-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-success-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-success-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-success-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-success-hover-border: $dx-gen-success-500 !default;
$dx-gen-color-button-text-success-hover-bg: $dx-gen-success-500 !default;
$dx-gen-color-button-text-success-normal-icon: $dx-gen-success-400 !default;
$dx-gen-color-button-text-success-normal-text: $dx-gen-success-400 !default;
$dx-gen-color-button-text-success-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-success-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-info-disabled-icon: $dx-gen-info-300 !default;
$dx-gen-color-button-text-info-disabled-text: $dx-gen-info-300 !default;
$dx-gen-color-button-text-info-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-info-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-info-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-info-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-info-active-border: $dx-gen-info-600 !default;
$dx-gen-color-button-text-info-active-bg: $dx-gen-info-600 !default;
$dx-gen-color-button-text-info-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-info-focused-icon: $dx-gen-info-400 !default;
$dx-gen-color-button-text-info-focused-text: $dx-gen-info-400 !default;
$dx-gen-color-button-text-info-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-info-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-info-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-info-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-info-hover-border: $dx-gen-info-500 !default;
$dx-gen-color-button-text-info-hover-bg: $dx-gen-info-500 !default;
$dx-gen-color-button-text-info-normal-icon: $dx-gen-info-400 !default;
$dx-gen-color-button-text-info-normal-text: $dx-gen-info-400 !default;
$dx-gen-color-button-text-info-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-info-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-secondary-disabled-icon: $dx-gen-secondary-300 !default;
$dx-gen-color-button-text-secondary-disabled-text: $dx-gen-secondary-300 !default;
$dx-gen-color-button-text-secondary-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-secondary-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-secondary-active-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-secondary-active-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-secondary-active-border: $dx-gen-secondary-300 !default;
$dx-gen-color-button-text-secondary-active-bg: $dx-gen-secondary-300 !default;
$dx-gen-color-button-text-secondary-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-secondary-focused-icon: $dx-gen-secondary-700 !default;
$dx-gen-color-button-text-secondary-focused-text: $dx-gen-secondary-700 !default;
$dx-gen-color-button-text-secondary-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-secondary-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-secondary-hover-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-secondary-hover-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-text-secondary-hover-border: $dx-gen-secondary-200 !default;
$dx-gen-color-button-text-secondary-hover-bg: $dx-gen-secondary-200 !default;
$dx-gen-color-button-text-secondary-normal-icon: $dx-gen-secondary-700 !default;
$dx-gen-color-button-text-secondary-normal-text: $dx-gen-secondary-700 !default;
$dx-gen-color-button-text-secondary-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-secondary-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-primary-disabled-icon: $dx-gen-primary-300 !default;
$dx-gen-color-button-text-primary-disabled-text: $dx-gen-primary-300 !default;
$dx-gen-color-button-text-primary-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-primary-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-primary-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-primary-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-primary-active-border: $dx-gen-primary-600 !default;
$dx-gen-color-button-text-primary-active-bg: $dx-gen-primary-600 !default;
$dx-gen-color-button-text-primary-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-primary-focused-icon: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-primary-focused-text: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-primary-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-primary-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-primary-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-primary-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-text-primary-hover-border: $dx-gen-primary-500 !default;
$dx-gen-color-button-text-primary-hover-bg: $dx-gen-primary-500 !default;
$dx-gen-color-button-text-primary-normal-icon: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-primary-normal-text: $dx-gen-primary-400 !default;
$dx-gen-color-button-text-primary-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-text-primary-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-disabled-icon: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-outline-none-disabled-text: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-outline-none-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-active-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-none-active-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-none-active-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-active-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-none-focused-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-none-focused-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-none-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-hover-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-none-hover-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-none-hover-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-hover-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-normal-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-none-normal-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-none-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-none-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-disabled-icon: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-outline-link-disabled-text: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-outline-link-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-active-icon: $dx-gen-primary-600 !default;
$dx-gen-color-button-outline-link-active-text: $dx-gen-primary-600 !default;
$dx-gen-color-button-outline-link-active-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-active-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-link-focused-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-link-focused-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-link-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-hover-icon: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-link-hover-text: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-link-hover-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-hover-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-normal-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-link-normal-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-link-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-link-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-light-disabled-icon: $dx-gen-light-dark-50 !default;
$dx-gen-color-button-outline-light-disabled-text: $dx-gen-light-dark-50 !default;
$dx-gen-color-button-outline-light-disabled-border: $dx-gen-light-dark-50 !default;
$dx-gen-color-button-outline-light-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-light-active-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-light-active-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-light-active-border: $dx-gen-light-dark-200 !default;
$dx-gen-color-button-outline-light-active-bg: $dx-gen-light-dark-200 !default;
$dx-gen-color-button-outline-light-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-light-focused-icon: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-outline-light-focused-text: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-outline-light-focused-border: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-outline-light-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-light-hover-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-light-hover-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-light-hover-border: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-outline-light-hover-bg: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-outline-light-normal-icon: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-outline-light-normal-text: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-outline-light-normal-border: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-outline-light-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-dark-disabled-icon: $dx-gen-light-dark-300 !default;
$dx-gen-color-button-outline-dark-disabled-text: $dx-gen-light-dark-300 !default;
$dx-gen-color-button-outline-dark-disabled-border: $dx-gen-light-dark-400 !default;
$dx-gen-color-button-outline-dark-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-dark-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-dark-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-dark-active-border: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-dark-active-bg: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-dark-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-dark-focused-icon: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-outline-dark-focused-text: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-outline-dark-focused-border: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-outline-dark-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-dark-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-dark-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-dark-hover-border: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-outline-dark-hover-bg: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-outline-dark-normal-icon: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-outline-dark-normal-text: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-outline-dark-normal-border: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-outline-dark-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-danger-disabled-icon: $dx-gen-danger-300 !default;
$dx-gen-color-button-outline-danger-disabled-text: $dx-gen-danger-300 !default;
$dx-gen-color-button-outline-danger-disabled-border: $dx-gen-danger-200 !default;
$dx-gen-color-button-outline-danger-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-danger-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-danger-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-danger-active-border: $dx-gen-danger-600 !default;
$dx-gen-color-button-outline-danger-active-bg: $dx-gen-danger-600 !default;
$dx-gen-color-button-outline-danger-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-danger-focused-icon: $dx-gen-danger-400 !default;
$dx-gen-color-button-outline-danger-focused-text: $dx-gen-danger-400 !default;
$dx-gen-color-button-outline-danger-focused-border: $dx-gen-danger-400 !default;
$dx-gen-color-button-outline-danger-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-danger-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-danger-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-danger-hover-border: $dx-gen-danger-500 !default;
$dx-gen-color-button-outline-danger-hover-bg: $dx-gen-danger-500 !default;
$dx-gen-color-button-outline-danger-normal-icon: $dx-gen-danger-400 !default;
$dx-gen-color-button-outline-danger-normal-text: $dx-gen-danger-400 !default;
$dx-gen-color-button-outline-danger-normal-border: $dx-gen-danger-400 !default;
$dx-gen-color-button-outline-danger-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-warning-disabled-icon: $dx-gen-warning-300 !default;
$dx-gen-color-button-outline-warning-disabled-text: $dx-gen-warning-300 !default;
$dx-gen-color-button-outline-warning-disabled-border: $dx-gen-warning-200 !default;
$dx-gen-color-button-outline-warning-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-warning-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-warning-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-warning-active-border: $dx-gen-warning-600 !default;
$dx-gen-color-button-outline-warning-active-bg: $dx-gen-warning-600 !default;
$dx-gen-color-button-outline-warning-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-warning-focused-icon: $dx-gen-warning-400 !default;
$dx-gen-color-button-outline-warning-focused-text: $dx-gen-warning-400 !default;
$dx-gen-color-button-outline-warning-focused-border: $dx-gen-warning-400 !default;
$dx-gen-color-button-outline-warning-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-warning-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-warning-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-warning-hover-border: $dx-gen-warning-500 !default;
$dx-gen-color-button-outline-warning-hover-bg: $dx-gen-warning-500 !default;
$dx-gen-color-button-outline-warning-normal-icon: $dx-gen-warning-400 !default;
$dx-gen-color-button-outline-warning-normal-text: $dx-gen-warning-400 !default;
$dx-gen-color-button-outline-warning-normal-border: $dx-gen-warning-400 !default;
$dx-gen-color-button-outline-warning-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-success-disabled-icon: $dx-gen-success-300 !default;
$dx-gen-color-button-outline-success-disabled-text: $dx-gen-success-300 !default;
$dx-gen-color-button-outline-success-disabled-border: $dx-gen-success-200 !default;
$dx-gen-color-button-outline-success-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-success-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-success-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-success-active-border: $dx-gen-success-600 !default;
$dx-gen-color-button-outline-success-active-bg: $dx-gen-success-600 !default;
$dx-gen-color-button-outline-success-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-success-focused-icon: $dx-gen-success-400 !default;
$dx-gen-color-button-outline-success-focused-text: $dx-gen-success-400 !default;
$dx-gen-color-button-outline-success-focused-border: $dx-gen-success-400 !default;
$dx-gen-color-button-outline-success-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-success-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-success-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-success-hover-border: $dx-gen-success-500 !default;
$dx-gen-color-button-outline-success-hover-bg: $dx-gen-success-500 !default;
$dx-gen-color-button-outline-success-normal-icon: $dx-gen-success-400 !default;
$dx-gen-color-button-outline-success-normal-text: $dx-gen-success-400 !default;
$dx-gen-color-button-outline-success-normal-border: $dx-gen-success-400 !default;
$dx-gen-color-button-outline-success-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-info-disabled-icon: $dx-gen-info-300 !default;
$dx-gen-color-button-outline-info-disabled-text: $dx-gen-info-300 !default;
$dx-gen-color-button-outline-info-disabled-border: $dx-gen-info-200 !default;
$dx-gen-color-button-outline-info-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-info-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-info-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-info-active-border: $dx-gen-info-600 !default;
$dx-gen-color-button-outline-info-active-bg: $dx-gen-info-600 !default;
$dx-gen-color-button-outline-info-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-info-focused-icon: $dx-gen-info-400 !default;
$dx-gen-color-button-outline-info-focused-text: $dx-gen-info-400 !default;
$dx-gen-color-button-outline-info-focused-border: $dx-gen-info-400 !default;
$dx-gen-color-button-outline-info-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-info-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-info-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-info-hover-border: $dx-gen-info-500 !default;
$dx-gen-color-button-outline-info-hover-bg: $dx-gen-info-500 !default;
$dx-gen-color-button-outline-info-normal-icon: $dx-gen-info-400 !default;
$dx-gen-color-button-outline-info-normal-text: $dx-gen-info-400 !default;
$dx-gen-color-button-outline-info-normal-border: $dx-gen-info-400 !default;
$dx-gen-color-button-outline-info-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-secondary-disabled-icon: $dx-gen-secondary-300 !default;
$dx-gen-color-button-outline-secondary-disabled-text: $dx-gen-secondary-300 !default;
$dx-gen-color-button-outline-secondary-disabled-border: $dx-gen-secondary-200 !default;
$dx-gen-color-button-outline-secondary-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-secondary-active-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-secondary-active-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-secondary-active-border: $dx-gen-secondary-300 !default;
$dx-gen-color-button-outline-secondary-active-bg: $dx-gen-secondary-300 !default;
$dx-gen-color-button-outline-secondary-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-secondary-focused-icon: $dx-gen-secondary-700 !default;
$dx-gen-color-button-outline-secondary-focused-text: $dx-gen-secondary-700 !default;
$dx-gen-color-button-outline-secondary-focused-border: $dx-gen-secondary-200 !default;
$dx-gen-color-button-outline-secondary-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-secondary-hover-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-secondary-hover-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-outline-secondary-hover-border: $dx-gen-secondary-200 !default;
$dx-gen-color-button-outline-secondary-hover-bg: $dx-gen-secondary-200 !default;
$dx-gen-color-button-outline-secondary-normal-icon: $dx-gen-secondary-700 !default;
$dx-gen-color-button-outline-secondary-normal-text: $dx-gen-secondary-700 !default;
$dx-gen-color-button-outline-secondary-normal-border: $dx-gen-secondary-200 !default;
$dx-gen-color-button-outline-secondary-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-primary-disabled-icon: $dx-gen-primary-300 !default;
$dx-gen-color-button-outline-primary-disabled-text: $dx-gen-primary-300 !default;
$dx-gen-color-button-outline-primary-disabled-border: $dx-gen-primary-200 !default;
$dx-gen-color-button-outline-primary-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-primary-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-primary-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-primary-active-border: $dx-gen-primary-600 !default;
$dx-gen-color-button-outline-primary-active-bg: $dx-gen-primary-600 !default;
$dx-gen-color-button-outline-primary-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-primary-focused-icon: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-primary-focused-text: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-primary-focused-border: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-primary-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-outline-primary-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-primary-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-outline-primary-hover-border: $dx-gen-primary-500 !default;
$dx-gen-color-button-outline-primary-hover-bg: $dx-gen-primary-500 !default;
$dx-gen-color-button-outline-primary-normal-icon: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-primary-normal-text: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-primary-normal-border: $dx-gen-primary-400 !default;
$dx-gen-color-button-outline-primary-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-disabled-icon: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-contained-none-disabled-text: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-contained-none-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-active-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-none-active-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-none-active-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-active-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-none-focused-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-none-focused-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-none-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-hover-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-none-hover-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-none-hover-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-hover-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-normal-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-none-normal-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-none-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-none-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-disabled-icon: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-contained-link-disabled-text: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-contained-link-disabled-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-disabled-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-active-icon: $dx-gen-primary-600 !default;
$dx-gen-color-button-contained-link-active-text: $dx-gen-primary-600 !default;
$dx-gen-color-button-contained-link-active-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-active-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-link-focused-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-link-focused-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-link-focused-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-focused-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-hover-icon: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-link-hover-text: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-link-hover-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-hover-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-normal-icon: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-link-normal-text: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-link-normal-border: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-link-normal-bg: $dx-gen-light-opacity-0 !default;
$dx-gen-color-button-contained-light-disabled-icon: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-contained-light-disabled-text: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-contained-light-disabled-border: $dx-gen-light-dark-50 !default;
$dx-gen-color-button-contained-light-disabled-bg: $dx-gen-light-dark-50 !default;
$dx-gen-color-button-contained-light-active-icon: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-light-active-text: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-light-active-border: $dx-gen-light-dark-200 !default;
$dx-gen-color-button-contained-light-active-bg: $dx-gen-light-dark-200 !default;
$dx-gen-color-button-contained-light-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-light-focused-icon: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-light-focused-text: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-light-focused-border: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-light-focused-bg: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-light-hover-icon: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-light-hover-text: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-light-hover-border: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-contained-light-hover-bg: $dx-gen-light-dark-100 !default;
$dx-gen-color-button-contained-light-normal-icon: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-light-normal-text: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-light-normal-border: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-light-normal-bg: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-disabled-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-disabled-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-disabled-border: $dx-gen-light-dark-600 !default;
$dx-gen-color-button-contained-dark-disabled-bg: $dx-gen-light-dark-600 !default;
$dx-gen-color-button-contained-dark-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-active-border: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-dark-active-bg: $dx-gen-light-dark-900 !default;
$dx-gen-color-button-contained-dark-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-dark-focused-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-focused-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-focused-border: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-contained-dark-focused-bg: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-contained-dark-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-hover-border: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-dark-hover-bg: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-dark-normal-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-normal-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-dark-normal-border: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-contained-dark-normal-bg: $dx-gen-light-dark-700 !default;
$dx-gen-color-button-contained-danger-disabled-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-disabled-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-disabled-border: $dx-gen-danger-200 !default;
$dx-gen-color-button-contained-danger-disabled-bg: $dx-gen-danger-200 !default;
$dx-gen-color-button-contained-danger-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-active-border: $dx-gen-danger-600 !default;
$dx-gen-color-button-contained-danger-active-bg: $dx-gen-danger-600 !default;
$dx-gen-color-button-contained-danger-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-danger-focused-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-focused-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-focused-border: $dx-gen-danger-400 !default;
$dx-gen-color-button-contained-danger-focused-bg: $dx-gen-danger-400 !default;
$dx-gen-color-button-contained-danger-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-hover-border: $dx-gen-danger-500 !default;
$dx-gen-color-button-contained-danger-hover-bg: $dx-gen-danger-500 !default;
$dx-gen-color-button-contained-danger-normal-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-normal-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-danger-normal-border: $dx-gen-danger-400 !default;
$dx-gen-color-button-contained-danger-normal-bg: $dx-gen-danger-400 !default;
$dx-gen-color-button-contained-warning-disabled-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-disabled-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-disabled-border: $dx-gen-warning-200 !default;
$dx-gen-color-button-contained-warning-disabled-bg: $dx-gen-warning-200 !default;
$dx-gen-color-button-contained-warning-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-active-border: $dx-gen-warning-600 !default;
$dx-gen-color-button-contained-warning-active-bg: $dx-gen-warning-600 !default;
$dx-gen-color-button-contained-warning-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-warning-focused-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-focused-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-focused-border: $dx-gen-warning-400 !default;
$dx-gen-color-button-contained-warning-focused-bg: $dx-gen-warning-400 !default;
$dx-gen-color-button-contained-warning-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-hover-border: $dx-gen-warning-500 !default;
$dx-gen-color-button-contained-warning-hover-bg: $dx-gen-warning-500 !default;
$dx-gen-color-button-contained-warning-normal-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-normal-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-warning-normal-border: $dx-gen-warning-400 !default;
$dx-gen-color-button-contained-warning-normal-bg: $dx-gen-warning-400 !default;
$dx-gen-color-button-contained-success-disabled-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-disabled-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-disabled-border: $dx-gen-success-200 !default;
$dx-gen-color-button-contained-success-disabled-bg: $dx-gen-success-200 !default;
$dx-gen-color-button-contained-success-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-active-border: $dx-gen-success-600 !default;
$dx-gen-color-button-contained-success-active-bg: $dx-gen-success-600 !default;
$dx-gen-color-button-contained-success-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-success-focused-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-focused-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-focused-border: $dx-gen-success-400 !default;
$dx-gen-color-button-contained-success-focused-bg: $dx-gen-success-400 !default;
$dx-gen-color-button-contained-success-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-hover-border: $dx-gen-success-500 !default;
$dx-gen-color-button-contained-success-hover-bg: $dx-gen-success-500 !default;
$dx-gen-color-button-contained-success-normal-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-normal-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-success-normal-border: $dx-gen-success-400 !default;
$dx-gen-color-button-contained-success-normal-bg: $dx-gen-success-400 !default;
$dx-gen-color-button-contained-info-disabled-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-disabled-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-disabled-border: $dx-gen-info-200 !default;
$dx-gen-color-button-contained-info-disabled-bg: $dx-gen-info-200 !default;
$dx-gen-color-button-contained-info-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-active-border: $dx-gen-info-600 !default;
$dx-gen-color-button-contained-info-active-bg: $dx-gen-info-600 !default;
$dx-gen-color-button-contained-info-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-info-focused-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-focused-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-focused-border: $dx-gen-info-400 !default;
$dx-gen-color-button-contained-info-focused-bg: $dx-gen-info-400 !default;
$dx-gen-color-button-contained-info-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-hover-border: $dx-gen-info-500 !default;
$dx-gen-color-button-contained-info-hover-bg: $dx-gen-info-500 !default;
$dx-gen-color-button-contained-info-normal-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-normal-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-info-normal-border: $dx-gen-info-400 !default;
$dx-gen-color-button-contained-info-normal-bg: $dx-gen-info-400 !default;
$dx-gen-color-button-contained-secondary-disabled-icon: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-contained-secondary-disabled-text: $dx-gen-light-dark-500 !default;
$dx-gen-color-button-contained-secondary-disabled-border: $dx-gen-secondary-50 !default;
$dx-gen-color-button-contained-secondary-disabled-bg: $dx-gen-secondary-50 !default;
$dx-gen-color-button-contained-secondary-active-icon: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-secondary-active-text: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-secondary-active-border: $dx-gen-secondary-300 !default;
$dx-gen-color-button-contained-secondary-active-bg: $dx-gen-secondary-300 !default;
$dx-gen-color-button-contained-secondary-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-secondary-focused-icon: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-secondary-focused-text: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-secondary-focused-border: $dx-gen-secondary-50 !default;
$dx-gen-color-button-contained-secondary-focused-bg: $dx-gen-secondary-50 !default;
$dx-gen-color-button-contained-secondary-hover-icon: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-secondary-hover-text: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-secondary-hover-border: $dx-gen-secondary-200 !default;
$dx-gen-color-button-contained-secondary-hover-bg: $dx-gen-secondary-200 !default;
$dx-gen-color-button-contained-secondary-normal-icon: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-secondary-normal-text: $dx-gen-light-dark-800 !default;
$dx-gen-color-button-contained-secondary-normal-border: $dx-gen-secondary-50 !default;
$dx-gen-color-button-contained-secondary-normal-bg: $dx-gen-secondary-50 !default;
$dx-gen-color-button-contained-primary-disabled-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-disabled-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-disabled-border: $dx-gen-primary-200 !default;
$dx-gen-color-button-contained-primary-disabled-bg: $dx-gen-primary-200 !default;
$dx-gen-color-button-contained-primary-active-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-active-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-active-border: $dx-gen-primary-600 !default;
$dx-gen-color-button-contained-primary-active-bg: $dx-gen-primary-600 !default;
$dx-gen-color-button-contained-primary-focused-frame: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-primary-focused-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-focused-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-focused-border: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-primary-focused-bg: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-primary-hover-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-hover-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-hover-border: $dx-gen-primary-500 !default;
$dx-gen-color-button-contained-primary-hover-bg: $dx-gen-primary-500 !default;
$dx-gen-color-button-contained-primary-normal-icon: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-normal-text: $dx-gen-light-opacity-white !default;
$dx-gen-color-button-contained-primary-normal-border: $dx-gen-primary-400 !default;
$dx-gen-color-button-contained-primary-normal-bg: $dx-gen-primary-400 !default;
$dx-gen-border-width-button-text-none-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-none-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-none-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-none-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-none-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-link-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-link-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-link-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-link-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-light-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-light-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-light-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-light-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-dark-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-dark-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-dark-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-dark-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-danger-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-danger-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-danger-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-danger-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-warning-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-warning-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-warning-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-warning-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-success-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-success-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-success-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-success-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-info-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-info-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-info-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-info-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-secondary-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-secondary-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-secondary-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-secondary-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-primary-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-primary-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-text-primary-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-text-primary-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-none-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-none-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-none-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-none-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-link-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-link-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-link-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-link-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-light-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-light-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-light-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-light-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-dark-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-dark-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-dark-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-dark-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-danger-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-danger-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-danger-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-danger-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-warning-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-warning-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-warning-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-warning-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-success-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-success-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-success-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-success-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-info-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-info-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-info-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-info-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-secondary-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-secondary-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-secondary-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-secondary-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-primary-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-primary-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-outline-primary-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-outline-primary-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-none-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-none-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-none-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-none-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-link-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-link-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-link-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-link-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-light-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-light-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-light-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-light-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-dark-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-dark-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-dark-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-dark-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-danger-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-danger-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-danger-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-danger-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-warning-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-warning-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-warning-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-warning-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-success-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-success-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-success-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-success-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-info-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-info-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-info-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-info-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-secondary-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-secondary-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-secondary-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-secondary-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-disabled-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-disabled-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-disabled-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-active-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-active-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-active-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-focused-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-focused-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-focused-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-focused-outer-box-large: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-primary-focused-outer-box-medium: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-primary-focused-outer-box-small: $dx-gen-border-width-2 !default;
$dx-gen-border-width-button-contained-primary-hover-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-hover-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-hover-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-normal-inner-box-large: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-normal-inner-box-medium: $dx-gen-border-width-1 !default;
$dx-gen-border-width-button-contained-primary-normal-inner-box-small: $dx-gen-border-width-1 !default;
$dx-gen-border-radius-button-text-none-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-none-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-none-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-none-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-none-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-link-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-link-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-link-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-link-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-light-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-light-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-light-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-light-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-dark-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-dark-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-dark-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-dark-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-danger-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-danger-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-danger-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-danger-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-warning-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-warning-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-warning-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-warning-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-success-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-success-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-success-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-success-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-info-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-info-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-info-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-info-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-secondary-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-secondary-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-secondary-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-secondary-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-primary-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-primary-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-text-primary-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-text-primary-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-none-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-none-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-none-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-none-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-link-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-link-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-link-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-link-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-light-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-light-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-light-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-light-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-dark-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-dark-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-dark-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-dark-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-danger-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-danger-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-danger-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-danger-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-warning-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-warning-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-warning-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-warning-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-success-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-success-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-success-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-success-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-info-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-info-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-info-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-info-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-secondary-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-secondary-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-secondary-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-secondary-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-primary-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-primary-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-outline-primary-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-outline-primary-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-none-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-none-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-none-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-none-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-link-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-link-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-link-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-link-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-light-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-light-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-light-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-light-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-dark-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-dark-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-dark-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-dark-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-danger-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-danger-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-danger-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-danger-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-warning-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-warning-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-warning-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-warning-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-success-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-success-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-success-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-success-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-info-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-info-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-info-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-info-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-secondary-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-secondary-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-secondary-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-secondary-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-disabled-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-disabled-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-disabled-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-disabled-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-disabled-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-disabled-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-active-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-active-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-active-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-active-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-active-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-active-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-focused-inner-box-large: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-primary-focused-inner-box-medium: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-primary-focused-inner-box-small: $dx-gen-border-radius-xs - $dx-gen-size-px !default;
$dx-gen-border-radius-button-contained-primary-focused-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-focused-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-focused-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-hover-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-hover-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-hover-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-hover-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-hover-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-hover-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-normal-inner-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-normal-inner-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-normal-inner-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-normal-outer-box-large: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-normal-outer-box-medium: $dx-gen-border-radius-xs !default;
$dx-gen-border-radius-button-contained-primary-normal-outer-box-small: $dx-gen-border-radius-xs !default;
$dx-gen-box-shadow-button-contained-light-disabled: $dx-gen-box-shadow-m !default;
$dx-gen-box-shadow-button-contained-light-active: $dx-gen-box-shadow-m !default;
$dx-gen-box-shadow-button-contained-light-hover: $dx-gen-box-shadow-m !default;
$dx-gen-box-shadow-button-contained-light-normal: $dx-gen-box-shadow-m !default;
$dx-gen-box-shadow-button-contained-secondary-disabled: $dx-gen-box-shadow-m !default;
$dx-gen-box-shadow-button-contained-secondary-active: $dx-gen-box-shadow-m !default;
$dx-gen-box-shadow-button-contained-secondary-hover: $dx-gen-box-shadow-m !default;
$dx-gen-box-shadow-button-contained-secondary-normal: $dx-gen-box-shadow-m !default;

$dx-button-map: (
  'border-radius': (
    'button': (
      'contained': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-primary-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-primary-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-primary-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-primary-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-primary-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-primary-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-primary-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-primary-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-primary-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-primary-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-primary-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-primary-disabled-inner-box-large
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-secondary-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-secondary-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-secondary-disabled-inner-box-large
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-info-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-info-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-info-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-info-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-info-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-info-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-info-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-info-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-info-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-info-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-info-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-info-disabled-inner-box-large
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-success-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-success-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-success-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-success-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-success-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-success-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-success-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-success-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-success-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-success-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-success-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-success-disabled-inner-box-large
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-warning-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-warning-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-warning-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-warning-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-warning-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-warning-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-warning-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-warning-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-warning-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-warning-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-warning-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-warning-disabled-inner-box-large
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-danger-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-danger-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-danger-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-danger-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-danger-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-danger-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-danger-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-danger-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-danger-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-danger-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-danger-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-danger-disabled-inner-box-large
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-dark-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-dark-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-dark-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-dark-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-dark-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-dark-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-dark-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-dark-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-dark-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-dark-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-dark-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-dark-disabled-inner-box-large
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-light-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-light-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-light-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-light-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-light-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-light-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-light-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-light-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-light-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-light-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-light-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-light-disabled-inner-box-large
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-link-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-link-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-link-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-link-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-link-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-link-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-link-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-link-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-link-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-link-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-link-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-link-disabled-inner-box-large
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-none-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-none-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-none-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-none-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-none-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-none-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-none-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-none-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-contained-none-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-contained-none-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-contained-none-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-contained-none-disabled-inner-box-large
            )
          )
        )
      ),
      'outline': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-primary-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-primary-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-primary-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-primary-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-primary-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-primary-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-primary-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-primary-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-primary-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-primary-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-primary-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-primary-disabled-inner-box-large
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-secondary-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-secondary-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-secondary-disabled-inner-box-large
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-info-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-info-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-info-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-info-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-info-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-info-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-info-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-info-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-info-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-info-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-info-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-info-disabled-inner-box-large
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-success-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-success-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-success-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-success-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-success-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-success-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-success-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-success-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-success-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-success-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-success-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-success-disabled-inner-box-large
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-warning-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-warning-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-warning-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-warning-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-warning-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-warning-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-warning-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-warning-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-warning-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-warning-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-warning-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-warning-disabled-inner-box-large
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-danger-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-danger-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-danger-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-danger-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-danger-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-danger-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-danger-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-danger-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-danger-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-danger-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-danger-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-danger-disabled-inner-box-large
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-dark-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-dark-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-dark-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-dark-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-dark-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-dark-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-dark-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-dark-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-dark-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-dark-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-dark-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-dark-disabled-inner-box-large
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-light-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-light-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-light-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-light-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-light-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-light-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-light-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-light-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-light-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-light-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-light-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-light-disabled-inner-box-large
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-link-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-link-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-link-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-link-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-link-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-link-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-link-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-link-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-link-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-link-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-link-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-link-disabled-inner-box-large
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-none-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-none-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-none-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-none-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-none-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-none-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-none-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-none-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-outline-none-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-outline-none-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-outline-none-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-outline-none-disabled-inner-box-large
            )
          )
        )
      ),
      'text': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-primary-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-primary-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-primary-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-primary-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-primary-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-primary-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-primary-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-primary-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-primary-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-primary-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-primary-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-primary-disabled-inner-box-large
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-secondary-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-secondary-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-secondary-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-secondary-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-secondary-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-secondary-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-secondary-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-secondary-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-secondary-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-secondary-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-secondary-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-secondary-disabled-inner-box-large
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-info-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-info-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-info-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-info-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-info-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-info-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-info-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-info-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-info-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-info-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-info-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-info-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-info-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-info-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-info-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-info-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-info-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-info-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-info-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-info-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-info-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-info-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-info-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-info-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-info-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-info-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-info-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-info-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-info-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-info-disabled-inner-box-large
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-success-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-success-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-success-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-success-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-success-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-success-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-success-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-success-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-success-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-success-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-success-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-success-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-success-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-success-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-success-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-success-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-success-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-success-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-success-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-success-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-success-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-success-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-success-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-success-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-success-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-success-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-success-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-success-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-success-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-success-disabled-inner-box-large
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-warning-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-warning-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-warning-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-warning-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-warning-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-warning-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-warning-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-warning-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-warning-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-warning-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-warning-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-warning-disabled-inner-box-large
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-danger-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-danger-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-danger-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-danger-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-danger-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-danger-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-danger-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-danger-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-danger-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-danger-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-danger-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-danger-disabled-inner-box-large
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-dark-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-dark-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-dark-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-dark-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-dark-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-dark-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-dark-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-dark-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-dark-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-dark-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-dark-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-dark-disabled-inner-box-large
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-light-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-light-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-light-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-light-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-light-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-light-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-light-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-light-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-light-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-light-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-light-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-light-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-light-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-light-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-light-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-light-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-light-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-light-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-light-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-light-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-light-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-light-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-light-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-light-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-light-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-light-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-light-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-light-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-light-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-light-disabled-inner-box-large
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-link-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-link-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-link-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-link-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-link-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-link-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-link-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-link-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-link-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-link-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-link-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-link-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-link-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-link-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-link-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-link-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-link-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-link-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-link-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-link-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-link-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-link-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-link-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-link-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-link-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-link-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-link-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-link-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-link-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-link-disabled-inner-box-large
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-none-normal-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-none-normal-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-none-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-none-normal-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-none-normal-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-none-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-none-hover-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-none-hover-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-none-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-none-hover-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-none-hover-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-none-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-none-focused-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-none-focused-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-none-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-none-focused-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-none-focused-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-none-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-none-active-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-none-active-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-none-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-none-active-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-none-active-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-none-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-radius-button-text-none-disabled-outer-box-small,
              'medium': $dx-gen-border-radius-button-text-none-disabled-outer-box-medium,
              'large': $dx-gen-border-radius-button-text-none-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-radius-button-text-none-disabled-inner-box-small,
              'medium': $dx-gen-border-radius-button-text-none-disabled-inner-box-medium,
              'large': $dx-gen-border-radius-button-text-none-disabled-inner-box-large
            )
          )
        )
      )
    )
  ),
  'opacity': (
    'button': (
      'contained': (
        'primary': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-primary-normal-text,
            'icon': $dx-gen-opacity-button-contained-primary-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-primary-hover-text,
            'icon': $dx-gen-opacity-button-contained-primary-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-primary-focused-text,
            'icon': $dx-gen-opacity-button-contained-primary-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-primary-active-text,
            'icon': $dx-gen-opacity-button-contained-primary-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-primary-disabled-text,
            'icon': $dx-gen-opacity-button-contained-primary-disabled-icon
          )
        ),
        'secondary': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-secondary-normal-text,
            'icon': $dx-gen-opacity-button-contained-secondary-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-secondary-hover-text,
            'icon': $dx-gen-opacity-button-contained-secondary-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-secondary-focused-text,
            'icon': $dx-gen-opacity-button-contained-secondary-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-secondary-active-text,
            'icon': $dx-gen-opacity-button-contained-secondary-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-secondary-disabled-text,
            'icon': $dx-gen-opacity-button-contained-secondary-disabled-icon
          )
        ),
        'info': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-info-normal-text,
            'icon': $dx-gen-opacity-button-contained-info-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-info-hover-text,
            'icon': $dx-gen-opacity-button-contained-info-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-info-focused-text,
            'icon': $dx-gen-opacity-button-contained-info-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-info-active-text,
            'icon': $dx-gen-opacity-button-contained-info-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-info-disabled-text,
            'icon': $dx-gen-opacity-button-contained-info-disabled-icon
          )
        ),
        'success': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-success-normal-text,
            'icon': $dx-gen-opacity-button-contained-success-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-success-hover-text,
            'icon': $dx-gen-opacity-button-contained-success-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-success-focused-text,
            'icon': $dx-gen-opacity-button-contained-success-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-success-active-text,
            'icon': $dx-gen-opacity-button-contained-success-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-success-disabled-text,
            'icon': $dx-gen-opacity-button-contained-success-disabled-icon
          )
        ),
        'warning': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-warning-normal-text,
            'icon': $dx-gen-opacity-button-contained-warning-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-warning-hover-text,
            'icon': $dx-gen-opacity-button-contained-warning-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-warning-focused-text,
            'icon': $dx-gen-opacity-button-contained-warning-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-warning-active-text,
            'icon': $dx-gen-opacity-button-contained-warning-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-warning-disabled-text,
            'icon': $dx-gen-opacity-button-contained-warning-disabled-icon
          )
        ),
        'danger': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-danger-normal-text,
            'icon': $dx-gen-opacity-button-contained-danger-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-danger-hover-text,
            'icon': $dx-gen-opacity-button-contained-danger-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-danger-focused-text,
            'icon': $dx-gen-opacity-button-contained-danger-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-danger-active-text,
            'icon': $dx-gen-opacity-button-contained-danger-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-danger-disabled-text,
            'icon': $dx-gen-opacity-button-contained-danger-disabled-icon
          )
        ),
        'dark': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-dark-normal-text,
            'icon': $dx-gen-opacity-button-contained-dark-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-dark-hover-text,
            'icon': $dx-gen-opacity-button-contained-dark-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-dark-focused-text,
            'icon': $dx-gen-opacity-button-contained-dark-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-dark-active-text,
            'icon': $dx-gen-opacity-button-contained-dark-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-dark-disabled-text,
            'icon': $dx-gen-opacity-button-contained-dark-disabled-icon
          )
        ),
        'light': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-light-normal-text,
            'icon': $dx-gen-opacity-button-contained-light-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-light-hover-text,
            'icon': $dx-gen-opacity-button-contained-light-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-light-focused-text,
            'icon': $dx-gen-opacity-button-contained-light-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-light-active-text,
            'icon': $dx-gen-opacity-button-contained-light-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-light-disabled-text,
            'icon': $dx-gen-opacity-button-contained-light-disabled-icon
          )
        ),
        'link': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-link-normal-text,
            'icon': $dx-gen-opacity-button-contained-link-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-link-hover-text,
            'icon': $dx-gen-opacity-button-contained-link-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-link-focused-text,
            'icon': $dx-gen-opacity-button-contained-link-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-link-active-text,
            'icon': $dx-gen-opacity-button-contained-link-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-link-disabled-text,
            'icon': $dx-gen-opacity-button-contained-link-disabled-icon
          )
        ),
        'none': (
          'normal': (
            'text': $dx-gen-opacity-button-contained-none-normal-text,
            'icon': $dx-gen-opacity-button-contained-none-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-contained-none-hover-text,
            'icon': $dx-gen-opacity-button-contained-none-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-contained-none-focused-text,
            'icon': $dx-gen-opacity-button-contained-none-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-contained-none-active-text,
            'icon': $dx-gen-opacity-button-contained-none-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-contained-none-disabled-text,
            'icon': $dx-gen-opacity-button-contained-none-disabled-icon
          )
        )
      ),
      'outline': (
        'primary': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-primary-normal-text,
            'icon': $dx-gen-opacity-button-outline-primary-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-primary-hover-text,
            'icon': $dx-gen-opacity-button-outline-primary-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-primary-focused-text,
            'icon': $dx-gen-opacity-button-outline-primary-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-primary-active-text,
            'icon': $dx-gen-opacity-button-outline-primary-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-primary-disabled-text,
            'icon': $dx-gen-opacity-button-outline-primary-disabled-icon
          )
        ),
        'secondary': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-secondary-normal-text,
            'icon': $dx-gen-opacity-button-outline-secondary-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-secondary-hover-text,
            'icon': $dx-gen-opacity-button-outline-secondary-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-secondary-focused-text,
            'icon': $dx-gen-opacity-button-outline-secondary-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-secondary-active-text,
            'icon': $dx-gen-opacity-button-outline-secondary-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-secondary-disabled-text,
            'icon': $dx-gen-opacity-button-outline-secondary-disabled-icon
          )
        ),
        'info': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-info-normal-text,
            'icon': $dx-gen-opacity-button-outline-info-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-info-hover-text,
            'icon': $dx-gen-opacity-button-outline-info-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-info-focused-text,
            'icon': $dx-gen-opacity-button-outline-info-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-info-active-text,
            'icon': $dx-gen-opacity-button-outline-info-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-info-disabled-text,
            'icon': $dx-gen-opacity-button-outline-info-disabled-icon
          )
        ),
        'success': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-success-normal-text,
            'icon': $dx-gen-opacity-button-outline-success-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-success-hover-text,
            'icon': $dx-gen-opacity-button-outline-success-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-success-focused-text,
            'icon': $dx-gen-opacity-button-outline-success-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-success-active-text,
            'icon': $dx-gen-opacity-button-outline-success-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-success-disabled-text,
            'icon': $dx-gen-opacity-button-outline-success-disabled-icon
          )
        ),
        'warning': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-warning-normal-text,
            'icon': $dx-gen-opacity-button-outline-warning-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-warning-hover-text,
            'icon': $dx-gen-opacity-button-outline-warning-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-warning-focused-text,
            'icon': $dx-gen-opacity-button-outline-warning-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-warning-active-text,
            'icon': $dx-gen-opacity-button-outline-warning-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-warning-disabled-text,
            'icon': $dx-gen-opacity-button-outline-warning-disabled-icon
          )
        ),
        'danger': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-danger-normal-text,
            'icon': $dx-gen-opacity-button-outline-danger-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-danger-hover-text,
            'icon': $dx-gen-opacity-button-outline-danger-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-danger-focused-text,
            'icon': $dx-gen-opacity-button-outline-danger-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-danger-active-text,
            'icon': $dx-gen-opacity-button-outline-danger-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-danger-disabled-text,
            'icon': $dx-gen-opacity-button-outline-danger-disabled-icon
          )
        ),
        'dark': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-dark-normal-text,
            'icon': $dx-gen-opacity-button-outline-dark-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-dark-hover-text,
            'icon': $dx-gen-opacity-button-outline-dark-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-dark-focused-text,
            'icon': $dx-gen-opacity-button-outline-dark-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-dark-active-text,
            'icon': $dx-gen-opacity-button-outline-dark-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-dark-disabled-text,
            'icon': $dx-gen-opacity-button-outline-dark-disabled-icon
          )
        ),
        'light': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-light-normal-text,
            'icon': $dx-gen-opacity-button-outline-light-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-light-hover-text,
            'icon': $dx-gen-opacity-button-outline-light-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-light-focused-text,
            'icon': $dx-gen-opacity-button-outline-light-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-light-active-text,
            'icon': $dx-gen-opacity-button-outline-light-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-light-disabled-text,
            'icon': $dx-gen-opacity-button-outline-light-disabled-icon
          )
        ),
        'link': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-link-normal-text,
            'icon': $dx-gen-opacity-button-outline-link-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-link-hover-text,
            'icon': $dx-gen-opacity-button-outline-link-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-link-focused-text,
            'icon': $dx-gen-opacity-button-outline-link-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-link-active-text,
            'icon': $dx-gen-opacity-button-outline-link-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-link-disabled-text,
            'icon': $dx-gen-opacity-button-outline-link-disabled-icon
          )
        ),
        'none': (
          'normal': (
            'text': $dx-gen-opacity-button-outline-none-normal-text,
            'icon': $dx-gen-opacity-button-outline-none-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-outline-none-hover-text,
            'icon': $dx-gen-opacity-button-outline-none-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-outline-none-focused-text,
            'icon': $dx-gen-opacity-button-outline-none-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-outline-none-active-text,
            'icon': $dx-gen-opacity-button-outline-none-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-outline-none-disabled-text,
            'icon': $dx-gen-opacity-button-outline-none-disabled-icon
          )
        )
      ),
      'text': (
        'primary': (
          'normal': (
            'text': $dx-gen-opacity-button-text-primary-normal-text,
            'icon': $dx-gen-opacity-button-text-primary-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-primary-hover-text,
            'icon': $dx-gen-opacity-button-text-primary-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-primary-focused-text,
            'icon': $dx-gen-opacity-button-text-primary-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-primary-active-text,
            'icon': $dx-gen-opacity-button-text-primary-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-primary-disabled-text,
            'icon': $dx-gen-opacity-button-text-primary-disabled-icon
          )
        ),
        'secondary': (
          'normal': (
            'text': $dx-gen-opacity-button-text-secondary-normal-text,
            'icon': $dx-gen-opacity-button-text-secondary-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-secondary-hover-text,
            'icon': $dx-gen-opacity-button-text-secondary-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-secondary-focused-text,
            'icon': $dx-gen-opacity-button-text-secondary-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-secondary-active-text,
            'icon': $dx-gen-opacity-button-text-secondary-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-secondary-disabled-text,
            'icon': $dx-gen-opacity-button-text-secondary-disabled-icon
          )
        ),
        'info': (
          'normal': (
            'text': $dx-gen-opacity-button-text-info-normal-text,
            'icon': $dx-gen-opacity-button-text-info-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-info-hover-text,
            'icon': $dx-gen-opacity-button-text-info-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-info-focused-text,
            'icon': $dx-gen-opacity-button-text-info-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-info-active-text,
            'icon': $dx-gen-opacity-button-text-info-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-info-disabled-text,
            'icon': $dx-gen-opacity-button-text-info-disabled-icon
          )
        ),
        'success': (
          'normal': (
            'text': $dx-gen-opacity-button-text-success-normal-text,
            'icon': $dx-gen-opacity-button-text-success-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-success-hover-text,
            'icon': $dx-gen-opacity-button-text-success-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-success-focused-text,
            'icon': $dx-gen-opacity-button-text-success-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-success-active-text,
            'icon': $dx-gen-opacity-button-text-success-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-success-disabled-text,
            'icon': $dx-gen-opacity-button-text-success-disabled-icon
          )
        ),
        'warning': (
          'normal': (
            'text': $dx-gen-opacity-button-text-warning-normal-text,
            'icon': $dx-gen-opacity-button-text-warning-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-warning-hover-text,
            'icon': $dx-gen-opacity-button-text-warning-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-warning-focused-text,
            'icon': $dx-gen-opacity-button-text-warning-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-warning-active-text,
            'icon': $dx-gen-opacity-button-text-warning-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-warning-disabled-text,
            'icon': $dx-gen-opacity-button-text-warning-disabled-icon
          )
        ),
        'danger': (
          'normal': (
            'text': $dx-gen-opacity-button-text-danger-normal-text,
            'icon': $dx-gen-opacity-button-text-danger-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-danger-hover-text,
            'icon': $dx-gen-opacity-button-text-danger-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-danger-focused-text,
            'icon': $dx-gen-opacity-button-text-danger-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-danger-active-text,
            'icon': $dx-gen-opacity-button-text-danger-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-danger-disabled-text,
            'icon': $dx-gen-opacity-button-text-danger-disabled-icon
          )
        ),
        'dark': (
          'normal': (
            'text': $dx-gen-opacity-button-text-dark-normal-text,
            'icon': $dx-gen-opacity-button-text-dark-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-dark-hover-text,
            'icon': $dx-gen-opacity-button-text-dark-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-dark-focused-text,
            'icon': $dx-gen-opacity-button-text-dark-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-dark-active-text,
            'icon': $dx-gen-opacity-button-text-dark-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-dark-disabled-text,
            'icon': $dx-gen-opacity-button-text-dark-disabled-icon
          )
        ),
        'light': (
          'normal': (
            'text': $dx-gen-opacity-button-text-light-normal-text,
            'icon': $dx-gen-opacity-button-text-light-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-light-hover-text,
            'icon': $dx-gen-opacity-button-text-light-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-light-focused-text,
            'icon': $dx-gen-opacity-button-text-light-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-light-active-text,
            'icon': $dx-gen-opacity-button-text-light-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-light-disabled-text,
            'icon': $dx-gen-opacity-button-text-light-disabled-icon
          )
        ),
        'link': (
          'normal': (
            'text': $dx-gen-opacity-button-text-link-normal-text,
            'icon': $dx-gen-opacity-button-text-link-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-link-hover-text,
            'icon': $dx-gen-opacity-button-text-link-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-link-focused-text,
            'icon': $dx-gen-opacity-button-text-link-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-link-active-text,
            'icon': $dx-gen-opacity-button-text-link-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-link-disabled-text,
            'icon': $dx-gen-opacity-button-text-link-disabled-icon
          )
        ),
        'none': (
          'normal': (
            'text': $dx-gen-opacity-button-text-none-normal-text,
            'icon': $dx-gen-opacity-button-text-none-normal-icon
          ),
          'hover': (
            'text': $dx-gen-opacity-button-text-none-hover-text,
            'icon': $dx-gen-opacity-button-text-none-hover-icon
          ),
          'focused': (
            'text': $dx-gen-opacity-button-text-none-focused-text,
            'icon': $dx-gen-opacity-button-text-none-focused-icon
          ),
          'active': (
            'text': $dx-gen-opacity-button-text-none-active-text,
            'icon': $dx-gen-opacity-button-text-none-active-icon
          ),
          'disabled': (
            'text': $dx-gen-opacity-button-text-none-disabled-text,
            'icon': $dx-gen-opacity-button-text-none-disabled-icon
          )
        )
      )
    )
  ),
  'box-shadow': (
    'button': (
      'contained': (
        'primary': (
          'normal': $dx-gen-box-shadow-button-contained-primary-normal,
          'hover': $dx-gen-box-shadow-button-contained-primary-hover,
          'focused': $dx-gen-box-shadow-button-contained-primary-focused,
          'active': $dx-gen-box-shadow-button-contained-primary-active,
          'disabled': $dx-gen-box-shadow-button-contained-primary-disabled
        ),
        'secondary': (
          'normal': $dx-gen-box-shadow-button-contained-secondary-normal,
          'hover': $dx-gen-box-shadow-button-contained-secondary-hover,
          'focused': $dx-gen-box-shadow-button-contained-secondary-focused,
          'active': $dx-gen-box-shadow-button-contained-secondary-active,
          'disabled': $dx-gen-box-shadow-button-contained-secondary-disabled
        ),
        'info': (
          'normal': $dx-gen-box-shadow-button-contained-info-normal,
          'hover': $dx-gen-box-shadow-button-contained-info-hover,
          'focused': $dx-gen-box-shadow-button-contained-info-focused,
          'active': $dx-gen-box-shadow-button-contained-info-active,
          'disabled': $dx-gen-box-shadow-button-contained-info-disabled
        ),
        'success': (
          'normal': $dx-gen-box-shadow-button-contained-success-normal,
          'hover': $dx-gen-box-shadow-button-contained-success-hover,
          'focused': $dx-gen-box-shadow-button-contained-success-focused,
          'active': $dx-gen-box-shadow-button-contained-success-active,
          'disabled': $dx-gen-box-shadow-button-contained-success-disabled
        ),
        'warning': (
          'normal': $dx-gen-box-shadow-button-contained-warning-normal,
          'hover': $dx-gen-box-shadow-button-contained-warning-hover,
          'focused': $dx-gen-box-shadow-button-contained-warning-focused,
          'active': $dx-gen-box-shadow-button-contained-warning-active,
          'disabled': $dx-gen-box-shadow-button-contained-warning-disabled
        ),
        'danger': (
          'normal': $dx-gen-box-shadow-button-contained-danger-normal,
          'hover': $dx-gen-box-shadow-button-contained-danger-hover,
          'focused': $dx-gen-box-shadow-button-contained-danger-focused,
          'active': $dx-gen-box-shadow-button-contained-danger-active,
          'disabled': $dx-gen-box-shadow-button-contained-danger-disabled
        ),
        'dark': (
          'normal': $dx-gen-box-shadow-button-contained-dark-normal,
          'hover': $dx-gen-box-shadow-button-contained-dark-hover,
          'focused': $dx-gen-box-shadow-button-contained-dark-focused,
          'active': $dx-gen-box-shadow-button-contained-dark-active,
          'disabled': $dx-gen-box-shadow-button-contained-dark-disabled
        ),
        'light': (
          'normal': $dx-gen-box-shadow-button-contained-light-normal,
          'hover': $dx-gen-box-shadow-button-contained-light-hover,
          'focused': $dx-gen-box-shadow-button-contained-light-focused,
          'active': $dx-gen-box-shadow-button-contained-light-active,
          'disabled': $dx-gen-box-shadow-button-contained-light-disabled
        ),
        'link': (
          'normal': $dx-gen-box-shadow-button-contained-link-normal,
          'hover': $dx-gen-box-shadow-button-contained-link-hover,
          'focused': $dx-gen-box-shadow-button-contained-link-focused,
          'active': $dx-gen-box-shadow-button-contained-link-active,
          'disabled': $dx-gen-box-shadow-button-contained-link-disabled
        ),
        'none': (
          'normal': $dx-gen-box-shadow-button-contained-none-normal,
          'hover': $dx-gen-box-shadow-button-contained-none-hover,
          'focused': $dx-gen-box-shadow-button-contained-none-focused,
          'active': $dx-gen-box-shadow-button-contained-none-active,
          'disabled': $dx-gen-box-shadow-button-contained-none-disabled
        )
      ),
      'outline': (
        'primary': (
          'normal': $dx-gen-box-shadow-button-outline-primary-normal,
          'hover': $dx-gen-box-shadow-button-outline-primary-hover,
          'focused': $dx-gen-box-shadow-button-outline-primary-focused,
          'active': $dx-gen-box-shadow-button-outline-primary-active,
          'disabled': $dx-gen-box-shadow-button-outline-primary-disabled
        ),
        'secondary': (
          'normal': $dx-gen-box-shadow-button-outline-secondary-normal,
          'hover': $dx-gen-box-shadow-button-outline-secondary-hover,
          'focused': $dx-gen-box-shadow-button-outline-secondary-focused,
          'active': $dx-gen-box-shadow-button-outline-secondary-active,
          'disabled': $dx-gen-box-shadow-button-outline-secondary-disabled
        ),
        'info': (
          'normal': $dx-gen-box-shadow-button-outline-info-normal,
          'hover': $dx-gen-box-shadow-button-outline-info-hover,
          'focused': $dx-gen-box-shadow-button-outline-info-focused,
          'active': $dx-gen-box-shadow-button-outline-info-active,
          'disabled': $dx-gen-box-shadow-button-outline-info-disabled
        ),
        'success': (
          'normal': $dx-gen-box-shadow-button-outline-success-normal,
          'hover': $dx-gen-box-shadow-button-outline-success-hover,
          'focused': $dx-gen-box-shadow-button-outline-success-focused,
          'active': $dx-gen-box-shadow-button-outline-success-active,
          'disabled': $dx-gen-box-shadow-button-outline-success-disabled
        ),
        'warning': (
          'normal': $dx-gen-box-shadow-button-outline-warning-normal,
          'hover': $dx-gen-box-shadow-button-outline-warning-hover,
          'focused': $dx-gen-box-shadow-button-outline-warning-focused,
          'active': $dx-gen-box-shadow-button-outline-warning-active,
          'disabled': $dx-gen-box-shadow-button-outline-warning-disabled
        ),
        'danger': (
          'normal': $dx-gen-box-shadow-button-outline-danger-normal,
          'hover': $dx-gen-box-shadow-button-outline-danger-hover,
          'focused': $dx-gen-box-shadow-button-outline-danger-focused,
          'active': $dx-gen-box-shadow-button-outline-danger-active,
          'disabled': $dx-gen-box-shadow-button-outline-danger-disabled
        ),
        'dark': (
          'normal': $dx-gen-box-shadow-button-outline-dark-normal,
          'hover': $dx-gen-box-shadow-button-outline-dark-hover,
          'focused': $dx-gen-box-shadow-button-outline-dark-focused,
          'active': $dx-gen-box-shadow-button-outline-dark-active,
          'disabled': $dx-gen-box-shadow-button-outline-dark-disabled
        ),
        'light': (
          'normal': $dx-gen-box-shadow-button-outline-light-normal,
          'hover': $dx-gen-box-shadow-button-outline-light-hover,
          'focused': $dx-gen-box-shadow-button-outline-light-focused,
          'active': $dx-gen-box-shadow-button-outline-light-active,
          'disabled': $dx-gen-box-shadow-button-outline-light-disabled
        ),
        'link': (
          'normal': $dx-gen-box-shadow-button-outline-link-normal,
          'hover': $dx-gen-box-shadow-button-outline-link-hover,
          'focused': $dx-gen-box-shadow-button-outline-link-focused,
          'active': $dx-gen-box-shadow-button-outline-link-active,
          'disabled': $dx-gen-box-shadow-button-outline-link-disabled
        ),
        'none': (
          'normal': $dx-gen-box-shadow-button-outline-none-normal,
          'hover': $dx-gen-box-shadow-button-outline-none-hover,
          'focused': $dx-gen-box-shadow-button-outline-none-focused,
          'active': $dx-gen-box-shadow-button-outline-none-active,
          'disabled': $dx-gen-box-shadow-button-outline-none-disabled
        )
      ),
      'text': (
        'primary': (
          'normal': $dx-gen-box-shadow-button-text-primary-normal,
          'hover': $dx-gen-box-shadow-button-text-primary-hover,
          'focused': $dx-gen-box-shadow-button-text-primary-focused,
          'active': $dx-gen-box-shadow-button-text-primary-active,
          'disabled': $dx-gen-box-shadow-button-text-primary-disabled
        ),
        'secondary': (
          'normal': $dx-gen-box-shadow-button-text-secondary-normal,
          'hover': $dx-gen-box-shadow-button-text-secondary-hover,
          'focused': $dx-gen-box-shadow-button-text-secondary-focused,
          'active': $dx-gen-box-shadow-button-text-secondary-active,
          'disabled': $dx-gen-box-shadow-button-text-secondary-disabled
        ),
        'info': (
          'normal': $dx-gen-box-shadow-button-text-info-normal,
          'hover': $dx-gen-box-shadow-button-text-info-hover,
          'focused': $dx-gen-box-shadow-button-text-info-focused,
          'active': $dx-gen-box-shadow-button-text-info-active,
          'disabled': $dx-gen-box-shadow-button-text-info-disabled
        ),
        'success': (
          'normal': $dx-gen-box-shadow-button-text-success-normal,
          'hover': $dx-gen-box-shadow-button-text-success-hover,
          'focused': $dx-gen-box-shadow-button-text-success-focused,
          'active': $dx-gen-box-shadow-button-text-success-active,
          'disabled': $dx-gen-box-shadow-button-text-success-disabled
        ),
        'warning': (
          'normal': $dx-gen-box-shadow-button-text-warning-normal,
          'hover': $dx-gen-box-shadow-button-text-warning-hover,
          'focused': $dx-gen-box-shadow-button-text-warning-focused,
          'active': $dx-gen-box-shadow-button-text-warning-active,
          'disabled': $dx-gen-box-shadow-button-text-warning-disabled
        ),
        'danger': (
          'normal': $dx-gen-box-shadow-button-text-danger-normal,
          'hover': $dx-gen-box-shadow-button-text-danger-hover,
          'focused': $dx-gen-box-shadow-button-text-danger-focused,
          'active': $dx-gen-box-shadow-button-text-danger-active,
          'disabled': $dx-gen-box-shadow-button-text-danger-disabled
        ),
        'dark': (
          'normal': $dx-gen-box-shadow-button-text-dark-normal,
          'hover': $dx-gen-box-shadow-button-text-dark-hover,
          'focused': $dx-gen-box-shadow-button-text-dark-focused,
          'active': $dx-gen-box-shadow-button-text-dark-active,
          'disabled': $dx-gen-box-shadow-button-text-dark-disabled
        ),
        'light': (
          'normal': $dx-gen-box-shadow-button-text-light-normal,
          'hover': $dx-gen-box-shadow-button-text-light-hover,
          'focused': $dx-gen-box-shadow-button-text-light-focused,
          'active': $dx-gen-box-shadow-button-text-light-active,
          'disabled': $dx-gen-box-shadow-button-text-light-disabled
        ),
        'link': (
          'normal': $dx-gen-box-shadow-button-text-link-normal,
          'hover': $dx-gen-box-shadow-button-text-link-hover,
          'focused': $dx-gen-box-shadow-button-text-link-focused,
          'active': $dx-gen-box-shadow-button-text-link-active,
          'disabled': $dx-gen-box-shadow-button-text-link-disabled
        ),
        'none': (
          'normal': $dx-gen-box-shadow-button-text-none-normal,
          'hover': $dx-gen-box-shadow-button-text-none-hover,
          'focused': $dx-gen-box-shadow-button-text-none-focused,
          'active': $dx-gen-box-shadow-button-text-none-active,
          'disabled': $dx-gen-box-shadow-button-text-none-disabled
        )
      )
    )
  ),
  'border-width': (
    'button': (
      'contained': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-primary-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-primary-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-primary-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-primary-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-primary-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-primary-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-primary-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-primary-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-primary-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-primary-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-primary-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-primary-disabled-inner-box-large
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-secondary-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-secondary-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-secondary-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-secondary-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-secondary-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-secondary-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-secondary-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-secondary-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-secondary-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-secondary-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-secondary-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-secondary-disabled-inner-box-large
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-info-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-info-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-info-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-info-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-info-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-info-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-info-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-info-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-info-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-info-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-info-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-info-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-info-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-info-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-info-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-info-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-info-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-info-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-info-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-info-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-info-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-info-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-info-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-info-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-info-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-info-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-info-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-info-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-info-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-info-disabled-inner-box-large
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-success-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-success-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-success-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-success-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-success-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-success-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-success-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-success-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-success-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-success-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-success-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-success-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-success-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-success-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-success-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-success-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-success-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-success-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-success-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-success-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-success-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-success-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-success-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-success-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-success-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-success-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-success-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-success-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-success-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-success-disabled-inner-box-large
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-warning-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-warning-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-warning-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-warning-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-warning-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-warning-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-warning-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-warning-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-warning-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-warning-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-warning-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-warning-disabled-inner-box-large
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-danger-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-danger-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-danger-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-danger-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-danger-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-danger-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-danger-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-danger-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-danger-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-danger-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-danger-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-danger-disabled-inner-box-large
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-dark-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-dark-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-dark-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-dark-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-dark-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-dark-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-dark-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-dark-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-dark-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-dark-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-dark-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-dark-disabled-inner-box-large
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-light-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-light-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-light-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-light-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-light-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-light-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-light-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-light-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-light-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-light-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-light-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-light-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-light-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-light-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-light-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-light-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-light-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-light-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-light-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-light-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-light-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-light-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-light-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-light-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-light-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-light-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-light-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-light-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-light-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-light-disabled-inner-box-large
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-link-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-link-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-link-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-link-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-link-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-link-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-link-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-link-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-link-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-link-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-link-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-link-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-link-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-link-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-link-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-link-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-link-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-link-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-link-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-link-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-link-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-link-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-link-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-link-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-link-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-link-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-link-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-link-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-link-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-link-disabled-inner-box-large
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-none-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-none-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-none-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-none-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-none-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-none-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-none-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-none-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-none-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-none-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-none-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-none-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-none-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-none-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-none-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-none-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-none-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-none-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-none-active-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-none-active-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-none-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-none-active-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-none-active-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-none-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-contained-none-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-contained-none-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-contained-none-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-contained-none-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-contained-none-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-contained-none-disabled-inner-box-large
            )
          )
        )
      ),
      'outline': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-primary-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-primary-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-primary-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-primary-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-primary-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-primary-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-primary-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-primary-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-primary-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-primary-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-primary-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-primary-disabled-inner-box-large
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-secondary-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-secondary-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-secondary-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-secondary-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-secondary-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-secondary-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-secondary-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-secondary-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-secondary-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-secondary-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-secondary-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-secondary-disabled-inner-box-large
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-info-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-info-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-info-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-info-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-info-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-info-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-info-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-info-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-info-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-info-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-info-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-info-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-info-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-info-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-info-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-info-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-info-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-info-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-info-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-info-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-info-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-info-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-info-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-info-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-info-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-info-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-info-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-info-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-info-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-info-disabled-inner-box-large
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-success-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-success-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-success-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-success-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-success-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-success-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-success-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-success-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-success-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-success-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-success-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-success-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-success-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-success-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-success-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-success-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-success-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-success-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-success-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-success-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-success-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-success-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-success-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-success-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-success-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-success-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-success-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-success-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-success-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-success-disabled-inner-box-large
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-warning-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-warning-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-warning-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-warning-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-warning-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-warning-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-warning-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-warning-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-warning-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-warning-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-warning-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-warning-disabled-inner-box-large
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-danger-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-danger-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-danger-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-danger-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-danger-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-danger-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-danger-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-danger-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-danger-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-danger-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-danger-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-danger-disabled-inner-box-large
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-dark-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-dark-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-dark-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-dark-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-dark-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-dark-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-dark-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-dark-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-dark-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-dark-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-dark-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-dark-disabled-inner-box-large
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-light-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-light-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-light-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-light-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-light-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-light-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-light-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-light-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-light-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-light-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-light-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-light-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-light-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-light-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-light-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-light-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-light-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-light-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-light-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-light-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-light-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-light-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-light-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-light-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-light-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-light-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-light-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-light-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-light-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-light-disabled-inner-box-large
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-link-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-link-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-link-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-link-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-link-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-link-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-link-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-link-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-link-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-link-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-link-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-link-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-link-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-link-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-link-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-link-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-link-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-link-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-link-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-link-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-link-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-link-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-link-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-link-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-link-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-link-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-link-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-link-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-link-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-link-disabled-inner-box-large
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-none-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-none-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-none-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-none-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-none-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-none-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-none-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-none-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-none-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-none-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-none-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-none-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-none-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-none-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-none-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-none-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-none-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-none-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-none-active-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-none-active-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-none-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-none-active-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-none-active-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-none-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-outline-none-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-outline-none-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-outline-none-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-outline-none-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-outline-none-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-outline-none-disabled-inner-box-large
            )
          )
        )
      ),
      'text': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-primary-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-primary-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-primary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-primary-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-primary-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-primary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-primary-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-primary-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-primary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-primary-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-primary-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-primary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-primary-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-primary-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-primary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-primary-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-primary-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-primary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-primary-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-primary-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-primary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-primary-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-primary-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-primary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-primary-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-primary-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-primary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-primary-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-primary-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-primary-disabled-inner-box-large
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-secondary-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-secondary-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-secondary-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-secondary-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-secondary-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-secondary-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-secondary-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-secondary-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-secondary-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-secondary-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-secondary-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-secondary-disabled-inner-box-large
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-info-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-info-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-info-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-info-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-info-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-info-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-info-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-info-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-info-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-info-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-info-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-info-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-info-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-info-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-info-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-info-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-info-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-info-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-info-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-info-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-info-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-info-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-info-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-info-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-info-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-info-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-info-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-info-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-info-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-info-disabled-inner-box-large
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-success-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-success-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-success-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-success-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-success-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-success-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-success-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-success-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-success-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-success-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-success-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-success-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-success-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-success-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-success-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-success-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-success-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-success-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-success-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-success-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-success-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-success-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-success-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-success-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-success-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-success-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-success-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-success-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-success-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-success-disabled-inner-box-large
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-warning-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-warning-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-warning-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-warning-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-warning-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-warning-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-warning-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-warning-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-warning-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-warning-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-warning-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-warning-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-warning-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-warning-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-warning-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-warning-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-warning-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-warning-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-warning-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-warning-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-warning-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-warning-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-warning-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-warning-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-warning-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-warning-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-warning-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-warning-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-warning-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-warning-disabled-inner-box-large
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-danger-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-danger-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-danger-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-danger-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-danger-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-danger-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-danger-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-danger-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-danger-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-danger-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-danger-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-danger-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-danger-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-danger-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-danger-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-danger-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-danger-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-danger-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-danger-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-danger-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-danger-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-danger-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-danger-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-danger-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-danger-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-danger-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-danger-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-danger-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-danger-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-danger-disabled-inner-box-large
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-dark-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-dark-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-dark-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-dark-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-dark-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-dark-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-dark-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-dark-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-dark-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-dark-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-dark-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-dark-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-dark-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-dark-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-dark-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-dark-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-dark-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-dark-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-dark-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-dark-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-dark-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-dark-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-dark-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-dark-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-dark-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-dark-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-dark-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-dark-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-dark-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-dark-disabled-inner-box-large
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-light-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-light-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-light-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-light-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-light-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-light-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-light-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-light-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-light-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-light-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-light-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-light-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-light-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-light-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-light-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-light-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-light-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-light-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-light-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-light-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-light-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-light-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-light-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-light-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-light-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-light-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-light-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-light-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-light-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-light-disabled-inner-box-large
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-link-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-link-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-link-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-link-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-link-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-link-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-link-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-link-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-link-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-link-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-link-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-link-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-link-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-link-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-link-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-link-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-link-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-link-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-link-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-link-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-link-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-link-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-link-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-link-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-link-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-link-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-link-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-link-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-link-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-link-disabled-inner-box-large
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-none-normal-outer-box-small,
              'medium': $dx-gen-border-width-button-text-none-normal-outer-box-medium,
              'large': $dx-gen-border-width-button-text-none-normal-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-none-normal-inner-box-small,
              'medium': $dx-gen-border-width-button-text-none-normal-inner-box-medium,
              'large': $dx-gen-border-width-button-text-none-normal-inner-box-large
            )
          ),
          'hover': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-none-hover-outer-box-small,
              'medium': $dx-gen-border-width-button-text-none-hover-outer-box-medium,
              'large': $dx-gen-border-width-button-text-none-hover-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-none-hover-inner-box-small,
              'medium': $dx-gen-border-width-button-text-none-hover-inner-box-medium,
              'large': $dx-gen-border-width-button-text-none-hover-inner-box-large
            )
          ),
          'focused': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-none-focused-outer-box-small,
              'medium': $dx-gen-border-width-button-text-none-focused-outer-box-medium,
              'large': $dx-gen-border-width-button-text-none-focused-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-none-focused-inner-box-small,
              'medium': $dx-gen-border-width-button-text-none-focused-inner-box-medium,
              'large': $dx-gen-border-width-button-text-none-focused-inner-box-large
            )
          ),
          'active': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-none-active-outer-box-small,
              'medium': $dx-gen-border-width-button-text-none-active-outer-box-medium,
              'large': $dx-gen-border-width-button-text-none-active-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-none-active-inner-box-small,
              'medium': $dx-gen-border-width-button-text-none-active-inner-box-medium,
              'large': $dx-gen-border-width-button-text-none-active-inner-box-large
            )
          ),
          'disabled': (
            'outer-box': (
              'small': $dx-gen-border-width-button-text-none-disabled-outer-box-small,
              'medium': $dx-gen-border-width-button-text-none-disabled-outer-box-medium,
              'large': $dx-gen-border-width-button-text-none-disabled-outer-box-large
            ),
            'inner-box': (
              'small': $dx-gen-border-width-button-text-none-disabled-inner-box-small,
              'medium': $dx-gen-border-width-button-text-none-disabled-inner-box-medium,
              'large': $dx-gen-border-width-button-text-none-disabled-inner-box-large
            )
          )
        )
      )
    )
  ),
  'color': (
    'button': (
      'contained': (
        'primary': (
          'normal': (
            'bg': $dx-gen-color-button-contained-primary-normal-bg,
            'border': $dx-gen-color-button-contained-primary-normal-border,
            'text': $dx-gen-color-button-contained-primary-normal-text,
            'icon': $dx-gen-color-button-contained-primary-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-primary-hover-bg,
            'border': $dx-gen-color-button-contained-primary-hover-border,
            'text': $dx-gen-color-button-contained-primary-hover-text,
            'icon': $dx-gen-color-button-contained-primary-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-primary-focused-bg,
            'border': $dx-gen-color-button-contained-primary-focused-border,
            'text': $dx-gen-color-button-contained-primary-focused-text,
            'icon': $dx-gen-color-button-contained-primary-focused-icon,
            'frame': $dx-gen-color-button-contained-primary-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-primary-active-bg,
            'border': $dx-gen-color-button-contained-primary-active-border,
            'text': $dx-gen-color-button-contained-primary-active-text,
            'icon': $dx-gen-color-button-contained-primary-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-primary-disabled-bg,
            'border': $dx-gen-color-button-contained-primary-disabled-border,
            'text': $dx-gen-color-button-contained-primary-disabled-text,
            'icon': $dx-gen-color-button-contained-primary-disabled-icon
          )
        ),
        'secondary': (
          'normal': (
            'bg': $dx-gen-color-button-contained-secondary-normal-bg,
            'border': $dx-gen-color-button-contained-secondary-normal-border,
            'text': $dx-gen-color-button-contained-secondary-normal-text,
            'icon': $dx-gen-color-button-contained-secondary-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-secondary-hover-bg,
            'border': $dx-gen-color-button-contained-secondary-hover-border,
            'text': $dx-gen-color-button-contained-secondary-hover-text,
            'icon': $dx-gen-color-button-contained-secondary-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-secondary-focused-bg,
            'border': $dx-gen-color-button-contained-secondary-focused-border,
            'text': $dx-gen-color-button-contained-secondary-focused-text,
            'icon': $dx-gen-color-button-contained-secondary-focused-icon,
            'frame': $dx-gen-color-button-contained-secondary-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-secondary-active-bg,
            'border': $dx-gen-color-button-contained-secondary-active-border,
            'text': $dx-gen-color-button-contained-secondary-active-text,
            'icon': $dx-gen-color-button-contained-secondary-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-secondary-disabled-bg,
            'border': $dx-gen-color-button-contained-secondary-disabled-border,
            'text': $dx-gen-color-button-contained-secondary-disabled-text,
            'icon': $dx-gen-color-button-contained-secondary-disabled-icon
          )
        ),
        'info': (
          'normal': (
            'bg': $dx-gen-color-button-contained-info-normal-bg,
            'border': $dx-gen-color-button-contained-info-normal-border,
            'text': $dx-gen-color-button-contained-info-normal-text,
            'icon': $dx-gen-color-button-contained-info-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-info-hover-bg,
            'border': $dx-gen-color-button-contained-info-hover-border,
            'text': $dx-gen-color-button-contained-info-hover-text,
            'icon': $dx-gen-color-button-contained-info-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-info-focused-bg,
            'border': $dx-gen-color-button-contained-info-focused-border,
            'text': $dx-gen-color-button-contained-info-focused-text,
            'icon': $dx-gen-color-button-contained-info-focused-icon,
            'frame': $dx-gen-color-button-contained-info-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-info-active-bg,
            'border': $dx-gen-color-button-contained-info-active-border,
            'text': $dx-gen-color-button-contained-info-active-text,
            'icon': $dx-gen-color-button-contained-info-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-info-disabled-bg,
            'border': $dx-gen-color-button-contained-info-disabled-border,
            'text': $dx-gen-color-button-contained-info-disabled-text,
            'icon': $dx-gen-color-button-contained-info-disabled-icon
          )
        ),
        'success': (
          'normal': (
            'bg': $dx-gen-color-button-contained-success-normal-bg,
            'border': $dx-gen-color-button-contained-success-normal-border,
            'text': $dx-gen-color-button-contained-success-normal-text,
            'icon': $dx-gen-color-button-contained-success-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-success-hover-bg,
            'border': $dx-gen-color-button-contained-success-hover-border,
            'text': $dx-gen-color-button-contained-success-hover-text,
            'icon': $dx-gen-color-button-contained-success-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-success-focused-bg,
            'border': $dx-gen-color-button-contained-success-focused-border,
            'text': $dx-gen-color-button-contained-success-focused-text,
            'icon': $dx-gen-color-button-contained-success-focused-icon,
            'frame': $dx-gen-color-button-contained-success-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-success-active-bg,
            'border': $dx-gen-color-button-contained-success-active-border,
            'text': $dx-gen-color-button-contained-success-active-text,
            'icon': $dx-gen-color-button-contained-success-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-success-disabled-bg,
            'border': $dx-gen-color-button-contained-success-disabled-border,
            'text': $dx-gen-color-button-contained-success-disabled-text,
            'icon': $dx-gen-color-button-contained-success-disabled-icon
          )
        ),
        'warning': (
          'normal': (
            'bg': $dx-gen-color-button-contained-warning-normal-bg,
            'border': $dx-gen-color-button-contained-warning-normal-border,
            'text': $dx-gen-color-button-contained-warning-normal-text,
            'icon': $dx-gen-color-button-contained-warning-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-warning-hover-bg,
            'border': $dx-gen-color-button-contained-warning-hover-border,
            'text': $dx-gen-color-button-contained-warning-hover-text,
            'icon': $dx-gen-color-button-contained-warning-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-warning-focused-bg,
            'border': $dx-gen-color-button-contained-warning-focused-border,
            'text': $dx-gen-color-button-contained-warning-focused-text,
            'icon': $dx-gen-color-button-contained-warning-focused-icon,
            'frame': $dx-gen-color-button-contained-warning-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-warning-active-bg,
            'border': $dx-gen-color-button-contained-warning-active-border,
            'text': $dx-gen-color-button-contained-warning-active-text,
            'icon': $dx-gen-color-button-contained-warning-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-warning-disabled-bg,
            'border': $dx-gen-color-button-contained-warning-disabled-border,
            'text': $dx-gen-color-button-contained-warning-disabled-text,
            'icon': $dx-gen-color-button-contained-warning-disabled-icon
          )
        ),
        'danger': (
          'normal': (
            'bg': $dx-gen-color-button-contained-danger-normal-bg,
            'border': $dx-gen-color-button-contained-danger-normal-border,
            'text': $dx-gen-color-button-contained-danger-normal-text,
            'icon': $dx-gen-color-button-contained-danger-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-danger-hover-bg,
            'border': $dx-gen-color-button-contained-danger-hover-border,
            'text': $dx-gen-color-button-contained-danger-hover-text,
            'icon': $dx-gen-color-button-contained-danger-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-danger-focused-bg,
            'border': $dx-gen-color-button-contained-danger-focused-border,
            'text': $dx-gen-color-button-contained-danger-focused-text,
            'icon': $dx-gen-color-button-contained-danger-focused-icon,
            'frame': $dx-gen-color-button-contained-danger-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-danger-active-bg,
            'border': $dx-gen-color-button-contained-danger-active-border,
            'text': $dx-gen-color-button-contained-danger-active-text,
            'icon': $dx-gen-color-button-contained-danger-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-danger-disabled-bg,
            'border': $dx-gen-color-button-contained-danger-disabled-border,
            'text': $dx-gen-color-button-contained-danger-disabled-text,
            'icon': $dx-gen-color-button-contained-danger-disabled-icon
          )
        ),
        'dark': (
          'normal': (
            'bg': $dx-gen-color-button-contained-dark-normal-bg,
            'border': $dx-gen-color-button-contained-dark-normal-border,
            'text': $dx-gen-color-button-contained-dark-normal-text,
            'icon': $dx-gen-color-button-contained-dark-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-dark-hover-bg,
            'border': $dx-gen-color-button-contained-dark-hover-border,
            'text': $dx-gen-color-button-contained-dark-hover-text,
            'icon': $dx-gen-color-button-contained-dark-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-dark-focused-bg,
            'border': $dx-gen-color-button-contained-dark-focused-border,
            'text': $dx-gen-color-button-contained-dark-focused-text,
            'icon': $dx-gen-color-button-contained-dark-focused-icon,
            'frame': $dx-gen-color-button-contained-dark-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-dark-active-bg,
            'border': $dx-gen-color-button-contained-dark-active-border,
            'text': $dx-gen-color-button-contained-dark-active-text,
            'icon': $dx-gen-color-button-contained-dark-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-dark-disabled-bg,
            'border': $dx-gen-color-button-contained-dark-disabled-border,
            'text': $dx-gen-color-button-contained-dark-disabled-text,
            'icon': $dx-gen-color-button-contained-dark-disabled-icon
          )
        ),
        'light': (
          'normal': (
            'bg': $dx-gen-color-button-contained-light-normal-bg,
            'border': $dx-gen-color-button-contained-light-normal-border,
            'text': $dx-gen-color-button-contained-light-normal-text,
            'icon': $dx-gen-color-button-contained-light-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-light-hover-bg,
            'border': $dx-gen-color-button-contained-light-hover-border,
            'text': $dx-gen-color-button-contained-light-hover-text,
            'icon': $dx-gen-color-button-contained-light-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-light-focused-bg,
            'border': $dx-gen-color-button-contained-light-focused-border,
            'text': $dx-gen-color-button-contained-light-focused-text,
            'icon': $dx-gen-color-button-contained-light-focused-icon,
            'frame': $dx-gen-color-button-contained-light-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-light-active-bg,
            'border': $dx-gen-color-button-contained-light-active-border,
            'text': $dx-gen-color-button-contained-light-active-text,
            'icon': $dx-gen-color-button-contained-light-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-light-disabled-bg,
            'border': $dx-gen-color-button-contained-light-disabled-border,
            'text': $dx-gen-color-button-contained-light-disabled-text,
            'icon': $dx-gen-color-button-contained-light-disabled-icon
          )
        ),
        'link': (
          'normal': (
            'bg': $dx-gen-color-button-contained-link-normal-bg,
            'border': $dx-gen-color-button-contained-link-normal-border,
            'text': $dx-gen-color-button-contained-link-normal-text,
            'icon': $dx-gen-color-button-contained-link-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-link-hover-bg,
            'border': $dx-gen-color-button-contained-link-hover-border,
            'text': $dx-gen-color-button-contained-link-hover-text,
            'icon': $dx-gen-color-button-contained-link-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-link-focused-bg,
            'border': $dx-gen-color-button-contained-link-focused-border,
            'text': $dx-gen-color-button-contained-link-focused-text,
            'icon': $dx-gen-color-button-contained-link-focused-icon,
            'frame': $dx-gen-color-button-contained-link-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-link-active-bg,
            'border': $dx-gen-color-button-contained-link-active-border,
            'text': $dx-gen-color-button-contained-link-active-text,
            'icon': $dx-gen-color-button-contained-link-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-link-disabled-bg,
            'border': $dx-gen-color-button-contained-link-disabled-border,
            'text': $dx-gen-color-button-contained-link-disabled-text,
            'icon': $dx-gen-color-button-contained-link-disabled-icon
          )
        ),
        'none': (
          'normal': (
            'bg': $dx-gen-color-button-contained-none-normal-bg,
            'border': $dx-gen-color-button-contained-none-normal-border,
            'text': $dx-gen-color-button-contained-none-normal-text,
            'icon': $dx-gen-color-button-contained-none-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-contained-none-hover-bg,
            'border': $dx-gen-color-button-contained-none-hover-border,
            'text': $dx-gen-color-button-contained-none-hover-text,
            'icon': $dx-gen-color-button-contained-none-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-contained-none-focused-bg,
            'border': $dx-gen-color-button-contained-none-focused-border,
            'text': $dx-gen-color-button-contained-none-focused-text,
            'icon': $dx-gen-color-button-contained-none-focused-icon,
            'frame': $dx-gen-color-button-contained-none-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-contained-none-active-bg,
            'border': $dx-gen-color-button-contained-none-active-border,
            'text': $dx-gen-color-button-contained-none-active-text,
            'icon': $dx-gen-color-button-contained-none-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-contained-none-disabled-bg,
            'border': $dx-gen-color-button-contained-none-disabled-border,
            'text': $dx-gen-color-button-contained-none-disabled-text,
            'icon': $dx-gen-color-button-contained-none-disabled-icon
          )
        )
      ),
      'outline': (
        'primary': (
          'normal': (
            'bg': $dx-gen-color-button-outline-primary-normal-bg,
            'border': $dx-gen-color-button-outline-primary-normal-border,
            'text': $dx-gen-color-button-outline-primary-normal-text,
            'icon': $dx-gen-color-button-outline-primary-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-primary-hover-bg,
            'border': $dx-gen-color-button-outline-primary-hover-border,
            'text': $dx-gen-color-button-outline-primary-hover-text,
            'icon': $dx-gen-color-button-outline-primary-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-primary-focused-bg,
            'border': $dx-gen-color-button-outline-primary-focused-border,
            'text': $dx-gen-color-button-outline-primary-focused-text,
            'icon': $dx-gen-color-button-outline-primary-focused-icon,
            'frame': $dx-gen-color-button-outline-primary-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-primary-active-bg,
            'border': $dx-gen-color-button-outline-primary-active-border,
            'text': $dx-gen-color-button-outline-primary-active-text,
            'icon': $dx-gen-color-button-outline-primary-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-primary-disabled-bg,
            'border': $dx-gen-color-button-outline-primary-disabled-border,
            'text': $dx-gen-color-button-outline-primary-disabled-text,
            'icon': $dx-gen-color-button-outline-primary-disabled-icon
          )
        ),
        'secondary': (
          'normal': (
            'bg': $dx-gen-color-button-outline-secondary-normal-bg,
            'border': $dx-gen-color-button-outline-secondary-normal-border,
            'text': $dx-gen-color-button-outline-secondary-normal-text,
            'icon': $dx-gen-color-button-outline-secondary-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-secondary-hover-bg,
            'border': $dx-gen-color-button-outline-secondary-hover-border,
            'text': $dx-gen-color-button-outline-secondary-hover-text,
            'icon': $dx-gen-color-button-outline-secondary-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-secondary-focused-bg,
            'border': $dx-gen-color-button-outline-secondary-focused-border,
            'text': $dx-gen-color-button-outline-secondary-focused-text,
            'icon': $dx-gen-color-button-outline-secondary-focused-icon,
            'frame': $dx-gen-color-button-outline-secondary-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-secondary-active-bg,
            'border': $dx-gen-color-button-outline-secondary-active-border,
            'text': $dx-gen-color-button-outline-secondary-active-text,
            'icon': $dx-gen-color-button-outline-secondary-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-secondary-disabled-bg,
            'border': $dx-gen-color-button-outline-secondary-disabled-border,
            'text': $dx-gen-color-button-outline-secondary-disabled-text,
            'icon': $dx-gen-color-button-outline-secondary-disabled-icon
          )
        ),
        'info': (
          'normal': (
            'bg': $dx-gen-color-button-outline-info-normal-bg,
            'border': $dx-gen-color-button-outline-info-normal-border,
            'text': $dx-gen-color-button-outline-info-normal-text,
            'icon': $dx-gen-color-button-outline-info-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-info-hover-bg,
            'border': $dx-gen-color-button-outline-info-hover-border,
            'text': $dx-gen-color-button-outline-info-hover-text,
            'icon': $dx-gen-color-button-outline-info-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-info-focused-bg,
            'border': $dx-gen-color-button-outline-info-focused-border,
            'text': $dx-gen-color-button-outline-info-focused-text,
            'icon': $dx-gen-color-button-outline-info-focused-icon,
            'frame': $dx-gen-color-button-outline-info-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-info-active-bg,
            'border': $dx-gen-color-button-outline-info-active-border,
            'text': $dx-gen-color-button-outline-info-active-text,
            'icon': $dx-gen-color-button-outline-info-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-info-disabled-bg,
            'border': $dx-gen-color-button-outline-info-disabled-border,
            'text': $dx-gen-color-button-outline-info-disabled-text,
            'icon': $dx-gen-color-button-outline-info-disabled-icon
          )
        ),
        'success': (
          'normal': (
            'bg': $dx-gen-color-button-outline-success-normal-bg,
            'border': $dx-gen-color-button-outline-success-normal-border,
            'text': $dx-gen-color-button-outline-success-normal-text,
            'icon': $dx-gen-color-button-outline-success-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-success-hover-bg,
            'border': $dx-gen-color-button-outline-success-hover-border,
            'text': $dx-gen-color-button-outline-success-hover-text,
            'icon': $dx-gen-color-button-outline-success-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-success-focused-bg,
            'border': $dx-gen-color-button-outline-success-focused-border,
            'text': $dx-gen-color-button-outline-success-focused-text,
            'icon': $dx-gen-color-button-outline-success-focused-icon,
            'frame': $dx-gen-color-button-outline-success-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-success-active-bg,
            'border': $dx-gen-color-button-outline-success-active-border,
            'text': $dx-gen-color-button-outline-success-active-text,
            'icon': $dx-gen-color-button-outline-success-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-success-disabled-bg,
            'border': $dx-gen-color-button-outline-success-disabled-border,
            'text': $dx-gen-color-button-outline-success-disabled-text,
            'icon': $dx-gen-color-button-outline-success-disabled-icon
          )
        ),
        'warning': (
          'normal': (
            'bg': $dx-gen-color-button-outline-warning-normal-bg,
            'border': $dx-gen-color-button-outline-warning-normal-border,
            'text': $dx-gen-color-button-outline-warning-normal-text,
            'icon': $dx-gen-color-button-outline-warning-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-warning-hover-bg,
            'border': $dx-gen-color-button-outline-warning-hover-border,
            'text': $dx-gen-color-button-outline-warning-hover-text,
            'icon': $dx-gen-color-button-outline-warning-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-warning-focused-bg,
            'border': $dx-gen-color-button-outline-warning-focused-border,
            'text': $dx-gen-color-button-outline-warning-focused-text,
            'icon': $dx-gen-color-button-outline-warning-focused-icon,
            'frame': $dx-gen-color-button-outline-warning-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-warning-active-bg,
            'border': $dx-gen-color-button-outline-warning-active-border,
            'text': $dx-gen-color-button-outline-warning-active-text,
            'icon': $dx-gen-color-button-outline-warning-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-warning-disabled-bg,
            'border': $dx-gen-color-button-outline-warning-disabled-border,
            'text': $dx-gen-color-button-outline-warning-disabled-text,
            'icon': $dx-gen-color-button-outline-warning-disabled-icon
          )
        ),
        'danger': (
          'normal': (
            'bg': $dx-gen-color-button-outline-danger-normal-bg,
            'border': $dx-gen-color-button-outline-danger-normal-border,
            'text': $dx-gen-color-button-outline-danger-normal-text,
            'icon': $dx-gen-color-button-outline-danger-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-danger-hover-bg,
            'border': $dx-gen-color-button-outline-danger-hover-border,
            'text': $dx-gen-color-button-outline-danger-hover-text,
            'icon': $dx-gen-color-button-outline-danger-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-danger-focused-bg,
            'border': $dx-gen-color-button-outline-danger-focused-border,
            'text': $dx-gen-color-button-outline-danger-focused-text,
            'icon': $dx-gen-color-button-outline-danger-focused-icon,
            'frame': $dx-gen-color-button-outline-danger-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-danger-active-bg,
            'border': $dx-gen-color-button-outline-danger-active-border,
            'text': $dx-gen-color-button-outline-danger-active-text,
            'icon': $dx-gen-color-button-outline-danger-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-danger-disabled-bg,
            'border': $dx-gen-color-button-outline-danger-disabled-border,
            'text': $dx-gen-color-button-outline-danger-disabled-text,
            'icon': $dx-gen-color-button-outline-danger-disabled-icon
          )
        ),
        'dark': (
          'normal': (
            'bg': $dx-gen-color-button-outline-dark-normal-bg,
            'border': $dx-gen-color-button-outline-dark-normal-border,
            'text': $dx-gen-color-button-outline-dark-normal-text,
            'icon': $dx-gen-color-button-outline-dark-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-dark-hover-bg,
            'border': $dx-gen-color-button-outline-dark-hover-border,
            'text': $dx-gen-color-button-outline-dark-hover-text,
            'icon': $dx-gen-color-button-outline-dark-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-dark-focused-bg,
            'border': $dx-gen-color-button-outline-dark-focused-border,
            'text': $dx-gen-color-button-outline-dark-focused-text,
            'icon': $dx-gen-color-button-outline-dark-focused-icon,
            'frame': $dx-gen-color-button-outline-dark-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-dark-active-bg,
            'border': $dx-gen-color-button-outline-dark-active-border,
            'text': $dx-gen-color-button-outline-dark-active-text,
            'icon': $dx-gen-color-button-outline-dark-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-dark-disabled-bg,
            'border': $dx-gen-color-button-outline-dark-disabled-border,
            'text': $dx-gen-color-button-outline-dark-disabled-text,
            'icon': $dx-gen-color-button-outline-dark-disabled-icon
          )
        ),
        'light': (
          'normal': (
            'bg': $dx-gen-color-button-outline-light-normal-bg,
            'border': $dx-gen-color-button-outline-light-normal-border,
            'text': $dx-gen-color-button-outline-light-normal-text,
            'icon': $dx-gen-color-button-outline-light-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-light-hover-bg,
            'border': $dx-gen-color-button-outline-light-hover-border,
            'text': $dx-gen-color-button-outline-light-hover-text,
            'icon': $dx-gen-color-button-outline-light-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-light-focused-bg,
            'border': $dx-gen-color-button-outline-light-focused-border,
            'text': $dx-gen-color-button-outline-light-focused-text,
            'icon': $dx-gen-color-button-outline-light-focused-icon,
            'frame': $dx-gen-color-button-outline-light-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-light-active-bg,
            'border': $dx-gen-color-button-outline-light-active-border,
            'text': $dx-gen-color-button-outline-light-active-text,
            'icon': $dx-gen-color-button-outline-light-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-light-disabled-bg,
            'border': $dx-gen-color-button-outline-light-disabled-border,
            'text': $dx-gen-color-button-outline-light-disabled-text,
            'icon': $dx-gen-color-button-outline-light-disabled-icon
          )
        ),
        'link': (
          'normal': (
            'bg': $dx-gen-color-button-outline-link-normal-bg,
            'border': $dx-gen-color-button-outline-link-normal-border,
            'text': $dx-gen-color-button-outline-link-normal-text,
            'icon': $dx-gen-color-button-outline-link-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-link-hover-bg,
            'border': $dx-gen-color-button-outline-link-hover-border,
            'text': $dx-gen-color-button-outline-link-hover-text,
            'icon': $dx-gen-color-button-outline-link-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-link-focused-bg,
            'border': $dx-gen-color-button-outline-link-focused-border,
            'text': $dx-gen-color-button-outline-link-focused-text,
            'icon': $dx-gen-color-button-outline-link-focused-icon,
            'frame': $dx-gen-color-button-outline-link-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-link-active-bg,
            'border': $dx-gen-color-button-outline-link-active-border,
            'text': $dx-gen-color-button-outline-link-active-text,
            'icon': $dx-gen-color-button-outline-link-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-link-disabled-bg,
            'border': $dx-gen-color-button-outline-link-disabled-border,
            'text': $dx-gen-color-button-outline-link-disabled-text,
            'icon': $dx-gen-color-button-outline-link-disabled-icon
          )
        ),
        'none': (
          'normal': (
            'bg': $dx-gen-color-button-outline-none-normal-bg,
            'border': $dx-gen-color-button-outline-none-normal-border,
            'text': $dx-gen-color-button-outline-none-normal-text,
            'icon': $dx-gen-color-button-outline-none-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-outline-none-hover-bg,
            'border': $dx-gen-color-button-outline-none-hover-border,
            'text': $dx-gen-color-button-outline-none-hover-text,
            'icon': $dx-gen-color-button-outline-none-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-outline-none-focused-bg,
            'border': $dx-gen-color-button-outline-none-focused-border,
            'text': $dx-gen-color-button-outline-none-focused-text,
            'icon': $dx-gen-color-button-outline-none-focused-icon,
            'frame': $dx-gen-color-button-outline-none-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-outline-none-active-bg,
            'border': $dx-gen-color-button-outline-none-active-border,
            'text': $dx-gen-color-button-outline-none-active-text,
            'icon': $dx-gen-color-button-outline-none-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-outline-none-disabled-bg,
            'border': $dx-gen-color-button-outline-none-disabled-border,
            'text': $dx-gen-color-button-outline-none-disabled-text,
            'icon': $dx-gen-color-button-outline-none-disabled-icon
          )
        )
      ),
      'text': (
        'primary': (
          'normal': (
            'bg': $dx-gen-color-button-text-primary-normal-bg,
            'border': $dx-gen-color-button-text-primary-normal-border,
            'text': $dx-gen-color-button-text-primary-normal-text,
            'icon': $dx-gen-color-button-text-primary-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-primary-hover-bg,
            'border': $dx-gen-color-button-text-primary-hover-border,
            'text': $dx-gen-color-button-text-primary-hover-text,
            'icon': $dx-gen-color-button-text-primary-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-primary-focused-bg,
            'border': $dx-gen-color-button-text-primary-focused-border,
            'text': $dx-gen-color-button-text-primary-focused-text,
            'icon': $dx-gen-color-button-text-primary-focused-icon,
            'frame': $dx-gen-color-button-text-primary-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-primary-active-bg,
            'border': $dx-gen-color-button-text-primary-active-border,
            'text': $dx-gen-color-button-text-primary-active-text,
            'icon': $dx-gen-color-button-text-primary-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-primary-disabled-bg,
            'border': $dx-gen-color-button-text-primary-disabled-border,
            'text': $dx-gen-color-button-text-primary-disabled-text,
            'icon': $dx-gen-color-button-text-primary-disabled-icon
          )
        ),
        'secondary': (
          'normal': (
            'bg': $dx-gen-color-button-text-secondary-normal-bg,
            'border': $dx-gen-color-button-text-secondary-normal-border,
            'text': $dx-gen-color-button-text-secondary-normal-text,
            'icon': $dx-gen-color-button-text-secondary-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-secondary-hover-bg,
            'border': $dx-gen-color-button-text-secondary-hover-border,
            'text': $dx-gen-color-button-text-secondary-hover-text,
            'icon': $dx-gen-color-button-text-secondary-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-secondary-focused-bg,
            'border': $dx-gen-color-button-text-secondary-focused-border,
            'text': $dx-gen-color-button-text-secondary-focused-text,
            'icon': $dx-gen-color-button-text-secondary-focused-icon,
            'frame': $dx-gen-color-button-text-secondary-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-secondary-active-bg,
            'border': $dx-gen-color-button-text-secondary-active-border,
            'text': $dx-gen-color-button-text-secondary-active-text,
            'icon': $dx-gen-color-button-text-secondary-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-secondary-disabled-bg,
            'border': $dx-gen-color-button-text-secondary-disabled-border,
            'text': $dx-gen-color-button-text-secondary-disabled-text,
            'icon': $dx-gen-color-button-text-secondary-disabled-icon
          )
        ),
        'info': (
          'normal': (
            'bg': $dx-gen-color-button-text-info-normal-bg,
            'border': $dx-gen-color-button-text-info-normal-border,
            'text': $dx-gen-color-button-text-info-normal-text,
            'icon': $dx-gen-color-button-text-info-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-info-hover-bg,
            'border': $dx-gen-color-button-text-info-hover-border,
            'text': $dx-gen-color-button-text-info-hover-text,
            'icon': $dx-gen-color-button-text-info-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-info-focused-bg,
            'border': $dx-gen-color-button-text-info-focused-border,
            'text': $dx-gen-color-button-text-info-focused-text,
            'icon': $dx-gen-color-button-text-info-focused-icon,
            'frame': $dx-gen-color-button-text-info-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-info-active-bg,
            'border': $dx-gen-color-button-text-info-active-border,
            'text': $dx-gen-color-button-text-info-active-text,
            'icon': $dx-gen-color-button-text-info-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-info-disabled-bg,
            'border': $dx-gen-color-button-text-info-disabled-border,
            'text': $dx-gen-color-button-text-info-disabled-text,
            'icon': $dx-gen-color-button-text-info-disabled-icon
          )
        ),
        'success': (
          'normal': (
            'bg': $dx-gen-color-button-text-success-normal-bg,
            'border': $dx-gen-color-button-text-success-normal-border,
            'text': $dx-gen-color-button-text-success-normal-text,
            'icon': $dx-gen-color-button-text-success-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-success-hover-bg,
            'border': $dx-gen-color-button-text-success-hover-border,
            'text': $dx-gen-color-button-text-success-hover-text,
            'icon': $dx-gen-color-button-text-success-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-success-focused-bg,
            'border': $dx-gen-color-button-text-success-focused-border,
            'text': $dx-gen-color-button-text-success-focused-text,
            'icon': $dx-gen-color-button-text-success-focused-icon,
            'frame': $dx-gen-color-button-text-success-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-success-active-bg,
            'border': $dx-gen-color-button-text-success-active-border,
            'text': $dx-gen-color-button-text-success-active-text,
            'icon': $dx-gen-color-button-text-success-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-success-disabled-bg,
            'border': $dx-gen-color-button-text-success-disabled-border,
            'text': $dx-gen-color-button-text-success-disabled-text,
            'icon': $dx-gen-color-button-text-success-disabled-icon
          )
        ),
        'warning': (
          'normal': (
            'bg': $dx-gen-color-button-text-warning-normal-bg,
            'border': $dx-gen-color-button-text-warning-normal-border,
            'text': $dx-gen-color-button-text-warning-normal-text,
            'icon': $dx-gen-color-button-text-warning-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-warning-hover-bg,
            'border': $dx-gen-color-button-text-warning-hover-border,
            'text': $dx-gen-color-button-text-warning-hover-text,
            'icon': $dx-gen-color-button-text-warning-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-warning-focused-bg,
            'border': $dx-gen-color-button-text-warning-focused-border,
            'text': $dx-gen-color-button-text-warning-focused-text,
            'icon': $dx-gen-color-button-text-warning-focused-icon,
            'frame': $dx-gen-color-button-text-warning-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-warning-active-bg,
            'border': $dx-gen-color-button-text-warning-active-border,
            'text': $dx-gen-color-button-text-warning-active-text,
            'icon': $dx-gen-color-button-text-warning-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-warning-disabled-bg,
            'border': $dx-gen-color-button-text-warning-disabled-border,
            'text': $dx-gen-color-button-text-warning-disabled-text,
            'icon': $dx-gen-color-button-text-warning-disabled-icon
          )
        ),
        'danger': (
          'normal': (
            'bg': $dx-gen-color-button-text-danger-normal-bg,
            'border': $dx-gen-color-button-text-danger-normal-border,
            'text': $dx-gen-color-button-text-danger-normal-text,
            'icon': $dx-gen-color-button-text-danger-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-danger-hover-bg,
            'border': $dx-gen-color-button-text-danger-hover-border,
            'text': $dx-gen-color-button-text-danger-hover-text,
            'icon': $dx-gen-color-button-text-danger-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-danger-focused-bg,
            'border': $dx-gen-color-button-text-danger-focused-border,
            'text': $dx-gen-color-button-text-danger-focused-text,
            'icon': $dx-gen-color-button-text-danger-focused-icon,
            'frame': $dx-gen-color-button-text-danger-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-danger-active-bg,
            'border': $dx-gen-color-button-text-danger-active-border,
            'text': $dx-gen-color-button-text-danger-active-text,
            'icon': $dx-gen-color-button-text-danger-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-danger-disabled-bg,
            'border': $dx-gen-color-button-text-danger-disabled-border,
            'text': $dx-gen-color-button-text-danger-disabled-text,
            'icon': $dx-gen-color-button-text-danger-disabled-icon
          )
        ),
        'dark': (
          'normal': (
            'bg': $dx-gen-color-button-text-dark-normal-bg,
            'border': $dx-gen-color-button-text-dark-normal-border,
            'text': $dx-gen-color-button-text-dark-normal-text,
            'icon': $dx-gen-color-button-text-dark-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-dark-hover-bg,
            'border': $dx-gen-color-button-text-dark-hover-border,
            'text': $dx-gen-color-button-text-dark-hover-text,
            'icon': $dx-gen-color-button-text-dark-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-dark-focused-bg,
            'border': $dx-gen-color-button-text-dark-focused-border,
            'text': $dx-gen-color-button-text-dark-focused-text,
            'icon': $dx-gen-color-button-text-dark-focused-icon,
            'frame': $dx-gen-color-button-text-dark-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-dark-active-bg,
            'border': $dx-gen-color-button-text-dark-active-border,
            'text': $dx-gen-color-button-text-dark-active-text,
            'icon': $dx-gen-color-button-text-dark-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-dark-disabled-bg,
            'border': $dx-gen-color-button-text-dark-disabled-border,
            'text': $dx-gen-color-button-text-dark-disabled-text,
            'icon': $dx-gen-color-button-text-dark-disabled-icon
          )
        ),
        'light': (
          'normal': (
            'bg': $dx-gen-color-button-text-light-normal-bg,
            'border': $dx-gen-color-button-text-light-normal-border,
            'text': $dx-gen-color-button-text-light-normal-text,
            'icon': $dx-gen-color-button-text-light-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-light-hover-bg,
            'border': $dx-gen-color-button-text-light-hover-border,
            'text': $dx-gen-color-button-text-light-hover-text,
            'icon': $dx-gen-color-button-text-light-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-light-focused-bg,
            'border': $dx-gen-color-button-text-light-focused-border,
            'text': $dx-gen-color-button-text-light-focused-text,
            'icon': $dx-gen-color-button-text-light-focused-icon,
            'frame': $dx-gen-color-button-text-light-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-light-active-bg,
            'border': $dx-gen-color-button-text-light-active-border,
            'text': $dx-gen-color-button-text-light-active-text,
            'icon': $dx-gen-color-button-text-light-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-light-disabled-bg,
            'border': $dx-gen-color-button-text-light-disabled-border,
            'text': $dx-gen-color-button-text-light-disabled-text,
            'icon': $dx-gen-color-button-text-light-disabled-icon
          )
        ),
        'link': (
          'normal': (
            'bg': $dx-gen-color-button-text-link-normal-bg,
            'border': $dx-gen-color-button-text-link-normal-border,
            'text': $dx-gen-color-button-text-link-normal-text,
            'icon': $dx-gen-color-button-text-link-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-link-hover-bg,
            'border': $dx-gen-color-button-text-link-hover-border,
            'text': $dx-gen-color-button-text-link-hover-text,
            'icon': $dx-gen-color-button-text-link-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-link-focused-bg,
            'border': $dx-gen-color-button-text-link-focused-border,
            'text': $dx-gen-color-button-text-link-focused-text,
            'icon': $dx-gen-color-button-text-link-focused-icon,
            'frame': $dx-gen-color-button-text-link-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-link-active-bg,
            'border': $dx-gen-color-button-text-link-active-border,
            'text': $dx-gen-color-button-text-link-active-text,
            'icon': $dx-gen-color-button-text-link-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-link-disabled-bg,
            'border': $dx-gen-color-button-text-link-disabled-border,
            'text': $dx-gen-color-button-text-link-disabled-text,
            'icon': $dx-gen-color-button-text-link-disabled-icon
          )
        ),
        'none': (
          'normal': (
            'bg': $dx-gen-color-button-text-none-normal-bg,
            'border': $dx-gen-color-button-text-none-normal-border,
            'text': $dx-gen-color-button-text-none-normal-text,
            'icon': $dx-gen-color-button-text-none-normal-icon
          ),
          'hover': (
            'bg': $dx-gen-color-button-text-none-hover-bg,
            'border': $dx-gen-color-button-text-none-hover-border,
            'text': $dx-gen-color-button-text-none-hover-text,
            'icon': $dx-gen-color-button-text-none-hover-icon
          ),
          'focused': (
            'bg': $dx-gen-color-button-text-none-focused-bg,
            'border': $dx-gen-color-button-text-none-focused-border,
            'text': $dx-gen-color-button-text-none-focused-text,
            'icon': $dx-gen-color-button-text-none-focused-icon,
            'frame': $dx-gen-color-button-text-none-focused-frame
          ),
          'active': (
            'bg': $dx-gen-color-button-text-none-active-bg,
            'border': $dx-gen-color-button-text-none-active-border,
            'text': $dx-gen-color-button-text-none-active-text,
            'icon': $dx-gen-color-button-text-none-active-icon
          ),
          'disabled': (
            'bg': $dx-gen-color-button-text-none-disabled-bg,
            'border': $dx-gen-color-button-text-none-disabled-border,
            'text': $dx-gen-color-button-text-none-disabled-text,
            'icon': $dx-gen-color-button-text-none-disabled-icon
          )
        )
      )
    )
  ),
  'composition': (
    'button': (
      'contained': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-primary-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-primary-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-primary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-primary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-primary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-secondary-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-secondary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-secondary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-secondary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-info-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-info-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-info-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-info-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-info-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-info-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-info-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-info-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-info-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-info-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-info-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-info-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-info-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-info-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-info-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-info-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-info-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-info-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-info-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-success-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-success-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-success-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-success-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-success-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-success-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-success-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-success-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-success-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-success-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-success-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-success-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-success-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-success-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-success-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-success-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-success-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-success-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-success-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-warning-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-warning-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-warning-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-warning-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-warning-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-danger-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-danger-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-danger-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-danger-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-danger-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-dark-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-dark-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-dark-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-dark-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-dark-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-light-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-light-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-light-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-light-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-light-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-light-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-light-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-light-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-light-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-light-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-light-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-light-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-light-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-light-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-light-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-light-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-light-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-light-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-light-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-link-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-link-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-link-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-link-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-link-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-link-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-link-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-link-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-link-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-link-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-link-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-link-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-link-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-link-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-link-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-link-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-link-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-link-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-link-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-none-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-none-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-none-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-none-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-none-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-none-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-none-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-none-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-none-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-none-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-none-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-none-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-contained-none-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-contained-none-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-contained-none-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-contained-none-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-contained-none-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-contained-none-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-contained-none-disabled-inner-box-large-item-spacing
              )
            )
          )
        )
      ),
      'outline': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-primary-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-primary-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-primary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-primary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-primary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-secondary-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-secondary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-secondary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-secondary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-info-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-info-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-info-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-info-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-info-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-info-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-info-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-info-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-info-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-info-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-info-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-info-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-info-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-info-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-info-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-info-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-info-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-info-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-info-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-success-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-success-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-success-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-success-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-success-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-success-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-success-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-success-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-success-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-success-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-success-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-success-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-success-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-success-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-success-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-success-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-success-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-success-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-success-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-warning-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-warning-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-warning-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-warning-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-warning-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-danger-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-danger-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-danger-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-danger-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-danger-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-dark-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-dark-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-dark-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-dark-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-dark-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-light-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-light-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-light-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-light-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-light-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-light-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-light-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-light-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-light-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-light-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-light-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-light-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-light-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-light-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-light-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-light-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-light-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-light-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-light-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-link-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-link-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-link-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-link-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-link-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-link-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-link-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-link-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-link-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-link-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-link-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-link-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-link-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-link-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-link-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-link-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-link-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-link-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-link-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-none-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-none-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-none-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-none-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-none-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-none-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-none-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-none-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-none-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-none-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-none-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-none-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-outline-none-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-outline-none-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-outline-none-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-outline-none-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-outline-none-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-outline-none-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-outline-none-disabled-inner-box-large-item-spacing
              )
            )
          )
        )
      ),
      'text': (
        'primary': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-primary-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-primary-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-primary-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-primary-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-primary-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-primary-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-primary-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-primary-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-primary-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-primary-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-primary-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-primary-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-primary-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-primary-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-primary-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-primary-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-primary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-primary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-primary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'secondary': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-secondary-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-secondary-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-secondary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-secondary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-secondary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'info': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-info-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-info-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-info-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-info-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-info-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-info-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-info-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-info-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-info-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-info-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-info-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-info-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-info-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-info-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-info-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-info-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-info-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-info-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-info-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-info-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-info-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-info-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-info-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-info-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-info-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-info-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-info-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-info-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-info-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-info-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-info-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-info-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-info-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'success': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-success-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-success-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-success-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-success-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-success-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-success-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-success-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-success-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-success-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-success-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-success-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-success-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-success-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-success-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-success-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-success-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-success-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-success-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-success-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-success-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-success-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-success-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-success-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-success-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-success-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-success-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-success-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-success-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-success-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-success-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-success-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-success-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-success-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'warning': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-warning-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-warning-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-warning-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-warning-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-warning-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-warning-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-warning-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-warning-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-warning-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-warning-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-warning-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-warning-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-warning-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-warning-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-warning-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-warning-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-warning-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-warning-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-warning-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'danger': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-danger-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-danger-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-danger-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-danger-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-danger-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-danger-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-danger-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-danger-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-danger-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-danger-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-danger-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-danger-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-danger-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-danger-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-danger-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-danger-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-danger-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-danger-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-danger-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'dark': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-dark-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-dark-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-dark-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-dark-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-dark-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-dark-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-dark-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-dark-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-dark-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-dark-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-dark-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-dark-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-dark-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-dark-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-dark-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-dark-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-dark-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-dark-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-dark-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'light': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-light-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-light-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-light-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-light-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-light-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-light-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-light-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-light-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-light-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-light-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-light-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-light-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-light-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-light-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-light-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-light-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-light-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-light-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-light-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-light-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-light-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-light-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-light-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-light-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-light-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-light-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-light-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-light-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-light-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-light-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-light-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-light-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-light-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'link': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-link-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-link-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-link-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-link-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-link-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-link-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-link-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-link-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-link-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-link-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-link-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-link-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-link-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-link-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-link-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-link-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-link-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-link-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-link-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-link-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-link-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-link-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-link-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-link-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-link-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-link-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-link-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-link-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-link-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-link-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-link-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-link-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-link-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'none': (
          'normal': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-none-normal-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-normal-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-none-normal-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-normal-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-none-normal-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-normal-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-none-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-none-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-none-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-none-hover-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-hover-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-none-hover-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-hover-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-none-hover-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-hover-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-none-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-none-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-none-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-none-focused-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-focused-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-none-focused-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-focused-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-none-focused-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-focused-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-none-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-none-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-none-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-none-active-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-active-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-none-active-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-active-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-none-active-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-active-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-none-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-none-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-none-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'outer-box': (
              'small': (
                'verticalPadding': $dx-gen-composition-button-text-none-disabled-outer-box-small-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-disabled-outer-box-small-horizontal-padding
              ),
              'medium': (
                'verticalPadding': $dx-gen-composition-button-text-none-disabled-outer-box-medium-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-disabled-outer-box-medium-horizontal-padding
              ),
              'large': (
                'verticalPadding': $dx-gen-composition-button-text-none-disabled-outer-box-large-vertical-padding,
                'horizontalPadding': $dx-gen-composition-button-text-none-disabled-outer-box-large-horizontal-padding
              )
            ),
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-button-text-none-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-button-text-none-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-button-text-none-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-button-text-none-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-button-text-none-disabled-inner-box-large-item-spacing
              )
            )
          )
        )
      )
    ),
    'icon-button': (
      'contained': (
        'primary': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-primary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'secondary': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-secondary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'info': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-info-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'success': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-success-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'warning': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-warning-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'danger': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-danger-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'dark': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-dark-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'light': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-contained-light-disabled-inner-box-large-item-spacing
              )
            )
          )
        )
      ),
      'outline': (
        'primary': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-primary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'secondary': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-secondary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'info': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-info-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'success': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-success-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'warning': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-warning-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'danger': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-danger-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'dark': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-dark-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'light': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-outline-light-disabled-inner-box-large-item-spacing
              )
            )
          )
        )
      ),
      'icon': (
        'primary': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-primary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'secondary': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-secondary-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'info': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-info-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'success': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-success-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'warning': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-warning-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'danger': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-danger-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'dark': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-dark-disabled-inner-box-large-item-spacing
              )
            )
          )
        ),
        'light': (
          'normal': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-normal-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-normal-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-normal-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-normal-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-normal-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-normal-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-normal-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-normal-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-normal-inner-box-large-item-spacing
              )
            )
          ),
          'hover': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-hover-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-hover-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-hover-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-hover-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-hover-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-hover-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-hover-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-hover-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-hover-inner-box-large-item-spacing
              )
            )
          ),
          'focused': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-focused-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-focused-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-focused-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-focused-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-focused-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-focused-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-focused-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-focused-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-focused-inner-box-large-item-spacing
              )
            )
          ),
          'active': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-active-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-active-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-active-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-active-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-active-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-active-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-active-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-active-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-active-inner-box-large-item-spacing
              )
            )
          ),
          'disabled': (
            'inner-box': (
              'small': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-small-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-small-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-small-item-spacing
              ),
              'medium': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-medium-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-medium-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-medium-item-spacing
              ),
              'large': (
                'horizontalPadding': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-large-horizontal-padding,
                'verticalPadding': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-large-vertical-padding,
                'itemSpacing': $dx-gen-composition-icon-button-icon-light-disabled-inner-box-large-item-spacing
              )
            )
          )
        )
      )
    )
  ),
  'text-decoration': (
    'button': (
      'link': (
        'underline': $dx-gen-text-decoration-button-link-underline
      )
    )
  ),
  'typography': (
    'button': (
      'contained': (
        'primary': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-primary-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-primary-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-primary-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-primary-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-primary-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-primary-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-primary-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-primary-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-primary-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-primary-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-primary-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-primary-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-primary-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-primary-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-primary-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-primary-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-primary-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-primary-large-text-case
          )
        ),
        'secondary': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-secondary-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-secondary-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-secondary-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-secondary-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-secondary-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-secondary-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-secondary-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-secondary-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-secondary-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-secondary-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-secondary-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-secondary-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-secondary-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-secondary-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-secondary-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-secondary-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-secondary-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-secondary-large-text-case
          )
        ),
        'info': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-info-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-info-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-info-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-info-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-info-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-info-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-info-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-info-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-info-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-info-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-info-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-info-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-info-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-info-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-info-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-info-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-info-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-info-large-text-case
          )
        ),
        'success': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-success-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-success-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-success-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-success-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-success-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-success-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-success-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-success-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-success-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-success-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-success-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-success-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-success-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-success-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-success-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-success-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-success-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-success-large-text-case
          )
        ),
        'warning': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-warning-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-warning-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-warning-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-warning-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-warning-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-warning-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-warning-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-warning-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-warning-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-warning-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-warning-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-warning-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-warning-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-warning-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-warning-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-warning-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-warning-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-warning-large-text-case
          )
        ),
        'danger': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-danger-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-danger-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-danger-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-danger-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-danger-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-danger-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-danger-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-danger-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-danger-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-danger-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-danger-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-danger-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-danger-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-danger-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-danger-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-danger-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-danger-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-danger-large-text-case
          )
        ),
        'dark': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-dark-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-dark-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-dark-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-dark-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-dark-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-dark-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-dark-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-dark-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-dark-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-dark-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-dark-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-dark-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-dark-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-dark-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-dark-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-dark-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-dark-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-dark-large-text-case
          )
        ),
        'light': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-light-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-light-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-light-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-light-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-light-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-light-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-light-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-light-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-light-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-light-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-light-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-light-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-light-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-light-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-light-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-light-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-light-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-light-large-text-case
          )
        ),
        'link': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-link-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-link-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-link-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-link-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-link-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-link-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-link-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-link-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-link-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-link-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-link-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-link-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-link-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-link-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-link-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-link-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-link-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-link-large-text-case
          )
        ),
        'none': (
          'small': (
            'fontFamily': $dx-gen-typography-button-contained-none-small-font-family,
            'fontWeight': $dx-gen-typography-button-contained-none-small-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-none-small-line-height,
            'fontSize': $dx-gen-typography-button-contained-none-small-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-none-small-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-none-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-contained-none-medium-font-family,
            'fontWeight': $dx-gen-typography-button-contained-none-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-none-medium-line-height,
            'fontSize': $dx-gen-typography-button-contained-none-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-none-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-none-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-contained-none-large-font-family,
            'fontWeight': $dx-gen-typography-button-contained-none-large-font-weight,
            'lineHeight': $dx-gen-typography-button-contained-none-large-line-height,
            'fontSize': $dx-gen-typography-button-contained-none-large-font-size,
            'letterSpacing': $dx-gen-typography-button-contained-none-large-letter-spacing,
            'textCase': $dx-gen-typography-button-contained-none-large-text-case
          )
        )
      ),
      'outline': (
        'primary': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-primary-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-primary-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-primary-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-primary-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-primary-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-primary-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-primary-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-primary-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-primary-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-primary-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-primary-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-primary-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-primary-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-primary-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-primary-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-primary-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-primary-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-primary-large-text-case
          )
        ),
        'secondary': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-secondary-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-secondary-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-secondary-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-secondary-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-secondary-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-secondary-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-secondary-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-secondary-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-secondary-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-secondary-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-secondary-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-secondary-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-secondary-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-secondary-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-secondary-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-secondary-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-secondary-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-secondary-large-text-case
          )
        ),
        'info': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-info-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-info-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-info-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-info-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-info-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-info-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-info-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-info-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-info-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-info-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-info-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-info-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-info-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-info-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-info-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-info-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-info-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-info-large-text-case
          )
        ),
        'success': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-success-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-success-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-success-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-success-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-success-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-success-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-success-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-success-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-success-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-success-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-success-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-success-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-success-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-success-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-success-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-success-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-success-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-success-large-text-case
          )
        ),
        'warning': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-warning-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-warning-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-warning-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-warning-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-warning-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-warning-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-warning-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-warning-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-warning-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-warning-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-warning-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-warning-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-warning-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-warning-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-warning-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-warning-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-warning-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-warning-large-text-case
          )
        ),
        'danger': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-danger-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-danger-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-danger-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-danger-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-danger-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-danger-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-danger-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-danger-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-danger-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-danger-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-danger-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-danger-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-danger-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-danger-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-danger-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-danger-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-danger-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-danger-large-text-case
          )
        ),
        'dark': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-dark-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-dark-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-dark-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-dark-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-dark-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-dark-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-dark-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-dark-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-dark-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-dark-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-dark-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-dark-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-dark-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-dark-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-dark-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-dark-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-dark-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-dark-large-text-case
          )
        ),
        'light': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-light-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-light-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-light-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-light-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-light-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-light-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-light-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-light-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-light-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-light-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-light-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-light-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-light-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-light-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-light-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-light-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-light-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-light-large-text-case
          )
        ),
        'link': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-link-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-link-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-link-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-link-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-link-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-link-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-link-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-link-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-link-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-link-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-link-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-link-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-link-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-link-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-link-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-link-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-link-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-link-large-text-case
          )
        ),
        'none': (
          'small': (
            'fontFamily': $dx-gen-typography-button-outline-none-small-font-family,
            'fontWeight': $dx-gen-typography-button-outline-none-small-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-none-small-line-height,
            'fontSize': $dx-gen-typography-button-outline-none-small-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-none-small-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-none-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-outline-none-medium-font-family,
            'fontWeight': $dx-gen-typography-button-outline-none-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-none-medium-line-height,
            'fontSize': $dx-gen-typography-button-outline-none-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-none-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-none-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-outline-none-large-font-family,
            'fontWeight': $dx-gen-typography-button-outline-none-large-font-weight,
            'lineHeight': $dx-gen-typography-button-outline-none-large-line-height,
            'fontSize': $dx-gen-typography-button-outline-none-large-font-size,
            'letterSpacing': $dx-gen-typography-button-outline-none-large-letter-spacing,
            'textCase': $dx-gen-typography-button-outline-none-large-text-case
          )
        )
      ),
      'text': (
        'primary': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-primary-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-primary-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-primary-small-line-height,
            'fontSize': $dx-gen-typography-button-text-primary-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-primary-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-primary-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-primary-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-primary-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-primary-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-primary-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-primary-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-primary-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-primary-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-primary-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-primary-large-line-height,
            'fontSize': $dx-gen-typography-button-text-primary-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-primary-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-primary-large-text-case
          )
        ),
        'secondary': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-secondary-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-secondary-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-secondary-small-line-height,
            'fontSize': $dx-gen-typography-button-text-secondary-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-secondary-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-secondary-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-secondary-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-secondary-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-secondary-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-secondary-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-secondary-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-secondary-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-secondary-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-secondary-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-secondary-large-line-height,
            'fontSize': $dx-gen-typography-button-text-secondary-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-secondary-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-secondary-large-text-case
          )
        ),
        'info': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-info-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-info-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-info-small-line-height,
            'fontSize': $dx-gen-typography-button-text-info-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-info-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-info-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-info-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-info-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-info-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-info-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-info-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-info-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-info-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-info-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-info-large-line-height,
            'fontSize': $dx-gen-typography-button-text-info-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-info-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-info-large-text-case
          )
        ),
        'success': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-success-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-success-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-success-small-line-height,
            'fontSize': $dx-gen-typography-button-text-success-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-success-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-success-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-success-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-success-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-success-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-success-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-success-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-success-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-success-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-success-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-success-large-line-height,
            'fontSize': $dx-gen-typography-button-text-success-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-success-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-success-large-text-case
          )
        ),
        'warning': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-warning-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-warning-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-warning-small-line-height,
            'fontSize': $dx-gen-typography-button-text-warning-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-warning-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-warning-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-warning-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-warning-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-warning-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-warning-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-warning-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-warning-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-warning-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-warning-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-warning-large-line-height,
            'fontSize': $dx-gen-typography-button-text-warning-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-warning-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-warning-large-text-case
          )
        ),
        'danger': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-danger-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-danger-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-danger-small-line-height,
            'fontSize': $dx-gen-typography-button-text-danger-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-danger-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-danger-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-danger-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-danger-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-danger-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-danger-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-danger-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-danger-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-danger-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-danger-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-danger-large-line-height,
            'fontSize': $dx-gen-typography-button-text-danger-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-danger-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-danger-large-text-case
          )
        ),
        'dark': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-dark-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-dark-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-dark-small-line-height,
            'fontSize': $dx-gen-typography-button-text-dark-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-dark-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-dark-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-dark-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-dark-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-dark-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-dark-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-dark-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-dark-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-dark-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-dark-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-dark-large-line-height,
            'fontSize': $dx-gen-typography-button-text-dark-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-dark-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-dark-large-text-case
          )
        ),
        'light': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-light-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-light-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-light-small-line-height,
            'fontSize': $dx-gen-typography-button-text-light-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-light-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-light-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-light-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-light-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-light-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-light-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-light-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-light-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-light-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-light-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-light-large-line-height,
            'fontSize': $dx-gen-typography-button-text-light-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-light-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-light-large-text-case
          )
        ),
        'link': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-link-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-link-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-link-small-line-height,
            'fontSize': $dx-gen-typography-button-text-link-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-link-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-link-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-link-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-link-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-link-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-link-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-link-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-link-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-link-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-link-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-link-large-line-height,
            'fontSize': $dx-gen-typography-button-text-link-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-link-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-link-large-text-case
          )
        ),
        'none': (
          'small': (
            'fontFamily': $dx-gen-typography-button-text-none-small-font-family,
            'fontWeight': $dx-gen-typography-button-text-none-small-font-weight,
            'lineHeight': $dx-gen-typography-button-text-none-small-line-height,
            'fontSize': $dx-gen-typography-button-text-none-small-font-size,
            'letterSpacing': $dx-gen-typography-button-text-none-small-letter-spacing,
            'textCase': $dx-gen-typography-button-text-none-small-text-case
          ),
          'medium': (
            'fontFamily': $dx-gen-typography-button-text-none-medium-font-family,
            'fontWeight': $dx-gen-typography-button-text-none-medium-font-weight,
            'lineHeight': $dx-gen-typography-button-text-none-medium-line-height,
            'fontSize': $dx-gen-typography-button-text-none-medium-font-size,
            'letterSpacing': $dx-gen-typography-button-text-none-medium-letter-spacing,
            'textCase': $dx-gen-typography-button-text-none-medium-text-case
          ),
          'large': (
            'fontFamily': $dx-gen-typography-button-text-none-large-font-family,
            'fontWeight': $dx-gen-typography-button-text-none-large-font-weight,
            'lineHeight': $dx-gen-typography-button-text-none-large-line-height,
            'fontSize': $dx-gen-typography-button-text-none-large-font-size,
            'letterSpacing': $dx-gen-typography-button-text-none-large-letter-spacing,
            'textCase': $dx-gen-typography-button-text-none-large-text-case
          )
        )
      )
    )
  )
);
